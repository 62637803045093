// source: stailer/externaladminrpc/external_admin_user_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var stailer_externaladminrpc_external_admin_user_pb = require('../../stailer/externaladminrpc/external_admin_user_pb.js');
goog.object.extend(proto, stailer_externaladminrpc_external_admin_user_pb);
var type_ec_site_pb = require('../../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
var type_platform_pb = require('../../type/platform_pb.js');
goog.object.extend(proto, type_platform_pb);
goog.exportSymbol('proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.CreateExternalAdminUserRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.CreateExternalAdminUserResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.DeleteRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.DeleteResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.GetByIdExternalAdminUserRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.GetByIdExternalAdminUserResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.GetExternalAdminUserRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.GetExternalAdminUserResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.InviteRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.InviteResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.ListRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.ListResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.LoginExternalAdminUserRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.LoginExternalAdminUserResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.UpdatePushNotificationSettingsRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.UpdatePushNotificationSettingsResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.UpdateSelfRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.UpdateSelfResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.UpdateUserRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.UpdateUserRequest.UpdateRole', null, global);
goog.exportSymbol('proto.stailer_external_admin.UpdateUserResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.CreateExternalAdminUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.CreateExternalAdminUserRequest.displayName = 'proto.stailer_external_admin.CreateExternalAdminUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.CreateExternalAdminUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.CreateExternalAdminUserResponse.displayName = 'proto.stailer_external_admin.CreateExternalAdminUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.LoginExternalAdminUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.LoginExternalAdminUserRequest.displayName = 'proto.stailer_external_admin.LoginExternalAdminUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.LoginExternalAdminUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.LoginExternalAdminUserResponse.displayName = 'proto.stailer_external_admin.LoginExternalAdminUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.ListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.ListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.ListRequest.displayName = 'proto.stailer_external_admin.ListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.ListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stailer_external_admin.ListResponse.repeatedFields_, null);
};
goog.inherits(proto.stailer_external_admin.ListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.ListResponse.displayName = 'proto.stailer_external_admin.ListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.GetExternalAdminUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.GetExternalAdminUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.GetExternalAdminUserRequest.displayName = 'proto.stailer_external_admin.GetExternalAdminUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.GetExternalAdminUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.GetExternalAdminUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.GetExternalAdminUserResponse.displayName = 'proto.stailer_external_admin.GetExternalAdminUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.GetByIdExternalAdminUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.GetByIdExternalAdminUserRequest.displayName = 'proto.stailer_external_admin.GetByIdExternalAdminUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.GetByIdExternalAdminUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.GetByIdExternalAdminUserResponse.displayName = 'proto.stailer_external_admin.GetByIdExternalAdminUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.UpdateUserRequest.displayName = 'proto.stailer_external_admin.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.UpdateUserRequest.UpdateRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.UpdateUserRequest.UpdateRole.displayName = 'proto.stailer_external_admin.UpdateUserRequest.UpdateRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.UpdateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.UpdateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.UpdateUserResponse.displayName = 'proto.stailer_external_admin.UpdateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.UpdateSelfRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.UpdateSelfRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.UpdateSelfRequest.displayName = 'proto.stailer_external_admin.UpdateSelfRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.UpdateSelfResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.UpdateSelfResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.UpdateSelfResponse.displayName = 'proto.stailer_external_admin.UpdateSelfResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.displayName = 'proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.displayName = 'proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.DeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.DeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.DeleteRequest.displayName = 'proto.stailer_external_admin.DeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.DeleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.DeleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.DeleteResponse.displayName = 'proto.stailer_external_admin.DeleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.InviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.InviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.InviteRequest.displayName = 'proto.stailer_external_admin.InviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.InviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.InviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.InviteResponse.displayName = 'proto.stailer_external_admin.InviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.displayName = 'proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.displayName = 'proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.UpdatePushNotificationSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.displayName = 'proto.stailer_external_admin.UpdatePushNotificationSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.UpdatePushNotificationSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.displayName = 'proto.stailer_external_admin.UpdatePushNotificationSettingsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.CreateExternalAdminUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    role: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of CreateExternalAdminUserRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.username;

  /** @type {?|undefined} */
  this.password;

  /** @type {?|undefined} */
  this.role;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserRequest}
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.CreateExternalAdminUserRequest();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.username != null && jspb.Message.setField(msg, 2, obj.username);
  obj.password != null && jspb.Message.setField(msg, 3, obj.password);
  obj.role != null && jspb.Message.setField(msg, 4, obj.role);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserRequest}
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.CreateExternalAdminUserRequest;
  return proto.stailer_external_admin.CreateExternalAdminUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserRequest}
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {!proto.stailer_external_admin.ExternalAdminUser.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.CreateExternalAdminUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserRequest} returns this
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserRequest} returns this
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserRequest} returns this
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ExternalAdminUser.Role role = 4;
 * @return {!proto.stailer_external_admin.ExternalAdminUser.Role}
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.getRole = function() {
  return /** @type {!proto.stailer_external_admin.ExternalAdminUser.Role} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.stailer_external_admin.ExternalAdminUser.Role} value
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserRequest} returns this
 */
proto.stailer_external_admin.CreateExternalAdminUserRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.CreateExternalAdminUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalAdminUser: (f = msg.getExternalAdminUser()) && stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of CreateExternalAdminUserResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.externalAdminUser;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserResponse}
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.CreateExternalAdminUserResponse();
  obj.externalAdminUser && jspb.Message.setWrapperField(
      msg, 1, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.fromObject(obj.externalAdminUser));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserResponse}
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.CreateExternalAdminUserResponse;
  return proto.stailer_external_admin.CreateExternalAdminUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserResponse}
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser;
      reader.readMessage(value,stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.deserializeBinaryFromReader);
      msg.setExternalAdminUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.CreateExternalAdminUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalAdminUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalAdminUser external_admin_user = 1;
 * @return {?proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.prototype.getExternalAdminUser = function() {
  return /** @type{?proto.stailer_external_admin.ExternalAdminUser} */ (
    jspb.Message.getWrapperField(this, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser, 1));
};


/**
 * @param {?proto.stailer_external_admin.ExternalAdminUser|undefined} value
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserResponse} returns this
*/
proto.stailer_external_admin.CreateExternalAdminUserResponse.prototype.setExternalAdminUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserResponse} returns this
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.prototype.clearExternalAdminUser = function() {
  return this.setExternalAdminUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.CreateExternalAdminUserResponse.prototype.hasExternalAdminUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.LoginExternalAdminUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.LoginExternalAdminUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of LoginExternalAdminUserRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.username;

  /** @type {?|undefined} */
  this.password;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.LoginExternalAdminUserRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserRequest}
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.LoginExternalAdminUserRequest();
  obj.siteType != null && jspb.Message.setField(msg, 1, obj.siteType);
  obj.username != null && jspb.Message.setField(msg, 2, obj.username);
  obj.password != null && jspb.Message.setField(msg, 3, obj.password);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserRequest}
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.LoginExternalAdminUserRequest;
  return proto.stailer_external_admin.LoginExternalAdminUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.LoginExternalAdminUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserRequest}
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.LoginExternalAdminUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.LoginExternalAdminUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional type.EcSite.SiteType site_type = 1;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserRequest} returns this
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserRequest} returns this
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserRequest} returns this
 */
proto.stailer_external_admin.LoginExternalAdminUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.LoginExternalAdminUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.LoginExternalAdminUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of LoginExternalAdminUserResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.customToken;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.LoginExternalAdminUserResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserResponse}
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.LoginExternalAdminUserResponse();
  obj.customToken != null && jspb.Message.setField(msg, 1, obj.customToken);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserResponse}
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.LoginExternalAdminUserResponse;
  return proto.stailer_external_admin.LoginExternalAdminUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.LoginExternalAdminUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserResponse}
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.LoginExternalAdminUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.LoginExternalAdminUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string custom_token = 1;
 * @return {string}
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.prototype.getCustomToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.LoginExternalAdminUserResponse} returns this
 */
proto.stailer_external_admin.LoginExternalAdminUserResponse.prototype.setCustomToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.ListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.ListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.ListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of ListRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.ListRequest.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.ListRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.ListRequest}
 */
proto.stailer_external_admin.ListRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.ListRequest();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.ListRequest}
 */
proto.stailer_external_admin.ListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.ListRequest;
  return proto.stailer_external_admin.ListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.ListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.ListRequest}
 */
proto.stailer_external_admin.ListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.ListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.ListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.ListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stailer_external_admin.ListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.ListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.ListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.ListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalAdminUsersList: jspb.Message.toObjectList(msg.getExternalAdminUsersList(),
    stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of ListResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.ListResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.externalAdminUsersList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.ListResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.ListResponse}
 */
proto.stailer_external_admin.ListResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.ListResponse();
  obj.externalAdminUsersList && jspb.Message.setRepeatedWrapperField(
      msg, 1, obj.externalAdminUsersList.map(
          stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.fromObject));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.ListResponse}
 */
proto.stailer_external_admin.ListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.ListResponse;
  return proto.stailer_external_admin.ListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.ListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.ListResponse}
 */
proto.stailer_external_admin.ListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser;
      reader.readMessage(value,stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.deserializeBinaryFromReader);
      msg.addExternalAdminUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.ListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.ListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.ListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalAdminUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ExternalAdminUser external_admin_users = 1;
 * @return {!Array<!proto.stailer_external_admin.ExternalAdminUser>}
 */
proto.stailer_external_admin.ListResponse.prototype.getExternalAdminUsersList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.ExternalAdminUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser, 1));
};


/**
 * @param {!Array<!proto.stailer_external_admin.ExternalAdminUser>} value
 * @return {!proto.stailer_external_admin.ListResponse} returns this
*/
proto.stailer_external_admin.ListResponse.prototype.setExternalAdminUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.stailer_external_admin.ExternalAdminUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.ListResponse.prototype.addExternalAdminUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.stailer_external_admin.ExternalAdminUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.ListResponse} returns this
 */
proto.stailer_external_admin.ListResponse.prototype.clearExternalAdminUsersList = function() {
  return this.setExternalAdminUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.GetExternalAdminUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.GetExternalAdminUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.GetExternalAdminUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetExternalAdminUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of GetExternalAdminUserRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.GetExternalAdminUserRequest.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.GetExternalAdminUserRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.GetExternalAdminUserRequest}
 */
proto.stailer_external_admin.GetExternalAdminUserRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.GetExternalAdminUserRequest();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.GetExternalAdminUserRequest}
 */
proto.stailer_external_admin.GetExternalAdminUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.GetExternalAdminUserRequest;
  return proto.stailer_external_admin.GetExternalAdminUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.GetExternalAdminUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.GetExternalAdminUserRequest}
 */
proto.stailer_external_admin.GetExternalAdminUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.GetExternalAdminUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.GetExternalAdminUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.GetExternalAdminUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetExternalAdminUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.GetExternalAdminUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.GetExternalAdminUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalAdminUser: (f = msg.getExternalAdminUser()) && stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of GetExternalAdminUserResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.externalAdminUser;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.GetExternalAdminUserResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.GetExternalAdminUserResponse}
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.GetExternalAdminUserResponse();
  obj.externalAdminUser && jspb.Message.setWrapperField(
      msg, 1, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.fromObject(obj.externalAdminUser));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.GetExternalAdminUserResponse}
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.GetExternalAdminUserResponse;
  return proto.stailer_external_admin.GetExternalAdminUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.GetExternalAdminUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.GetExternalAdminUserResponse}
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser;
      reader.readMessage(value,stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.deserializeBinaryFromReader);
      msg.setExternalAdminUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.GetExternalAdminUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.GetExternalAdminUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalAdminUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalAdminUser external_admin_user = 1;
 * @return {?proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.prototype.getExternalAdminUser = function() {
  return /** @type{?proto.stailer_external_admin.ExternalAdminUser} */ (
    jspb.Message.getWrapperField(this, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser, 1));
};


/**
 * @param {?proto.stailer_external_admin.ExternalAdminUser|undefined} value
 * @return {!proto.stailer_external_admin.GetExternalAdminUserResponse} returns this
*/
proto.stailer_external_admin.GetExternalAdminUserResponse.prototype.setExternalAdminUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.GetExternalAdminUserResponse} returns this
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.prototype.clearExternalAdminUser = function() {
  return this.setExternalAdminUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.GetExternalAdminUserResponse.prototype.hasExternalAdminUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.GetByIdExternalAdminUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.GetByIdExternalAdminUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of GetByIdExternalAdminUserRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.GetByIdExternalAdminUserRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.GetByIdExternalAdminUserRequest}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.GetByIdExternalAdminUserRequest();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.GetByIdExternalAdminUserRequest}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.GetByIdExternalAdminUserRequest;
  return proto.stailer_external_admin.GetByIdExternalAdminUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.GetByIdExternalAdminUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.GetByIdExternalAdminUserRequest}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.GetByIdExternalAdminUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.GetByIdExternalAdminUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.GetByIdExternalAdminUserRequest} returns this
 */
proto.stailer_external_admin.GetByIdExternalAdminUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.GetByIdExternalAdminUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.GetByIdExternalAdminUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalAdminUser: (f = msg.getExternalAdminUser()) && stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of GetByIdExternalAdminUserResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.externalAdminUser;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.GetByIdExternalAdminUserResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.GetByIdExternalAdminUserResponse}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.GetByIdExternalAdminUserResponse();
  obj.externalAdminUser && jspb.Message.setWrapperField(
      msg, 1, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.fromObject(obj.externalAdminUser));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.GetByIdExternalAdminUserResponse}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.GetByIdExternalAdminUserResponse;
  return proto.stailer_external_admin.GetByIdExternalAdminUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.GetByIdExternalAdminUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.GetByIdExternalAdminUserResponse}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser;
      reader.readMessage(value,stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.deserializeBinaryFromReader);
      msg.setExternalAdminUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.GetByIdExternalAdminUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.GetByIdExternalAdminUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalAdminUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalAdminUser external_admin_user = 1;
 * @return {?proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.prototype.getExternalAdminUser = function() {
  return /** @type{?proto.stailer_external_admin.ExternalAdminUser} */ (
    jspb.Message.getWrapperField(this, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser, 1));
};


/**
 * @param {?proto.stailer_external_admin.ExternalAdminUser|undefined} value
 * @return {!proto.stailer_external_admin.GetByIdExternalAdminUserResponse} returns this
*/
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.prototype.setExternalAdminUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.GetByIdExternalAdminUserResponse} returns this
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.prototype.clearExternalAdminUser = function() {
  return this.setExternalAdminUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.GetByIdExternalAdminUserResponse.prototype.hasExternalAdminUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shopId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    updateRole: (f = msg.getUpdateRole()) && proto.stailer_external_admin.UpdateUserRequest.UpdateRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UpdateUserRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.UpdateUserRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.shopId;

  /** @type {?|undefined} */
  this.updateRole;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.UpdateUserRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.UpdateUserRequest}
 */
proto.stailer_external_admin.UpdateUserRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.UpdateUserRequest();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  obj.shopId != null && jspb.Message.setField(msg, 3, obj.shopId);
  obj.updateRole && jspb.Message.setWrapperField(
      msg, 4, proto.stailer_external_admin.UpdateUserRequest.UpdateRole.fromObject(obj.updateRole));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.UpdateUserRequest}
 */
proto.stailer_external_admin.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.UpdateUserRequest;
  return proto.stailer_external_admin.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.UpdateUserRequest}
 */
proto.stailer_external_admin.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    case 4:
      var value = new proto.stailer_external_admin.UpdateUserRequest.UpdateRole;
      reader.readMessage(value,proto.stailer_external_admin.UpdateUserRequest.UpdateRole.deserializeBinaryFromReader);
      msg.setUpdateRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdateRole();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.stailer_external_admin.UpdateUserRequest.UpdateRole.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.UpdateUserRequest.UpdateRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.UpdateUserRequest.UpdateRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UpdateRole as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.role;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.UpdateUserRequest.UpdateRole.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.UpdateUserRequest.UpdateRole}
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.UpdateUserRequest.UpdateRole();
  obj.role != null && jspb.Message.setField(msg, 1, obj.role);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.UpdateUserRequest.UpdateRole}
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.UpdateUserRequest.UpdateRole;
  return proto.stailer_external_admin.UpdateUserRequest.UpdateRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.UpdateUserRequest.UpdateRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.UpdateUserRequest.UpdateRole}
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.stailer_external_admin.ExternalAdminUser.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.UpdateUserRequest.UpdateRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.UpdateUserRequest.UpdateRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ExternalAdminUser.Role role = 1;
 * @return {!proto.stailer_external_admin.ExternalAdminUser.Role}
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.prototype.getRole = function() {
  return /** @type {!proto.stailer_external_admin.ExternalAdminUser.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.stailer_external_admin.ExternalAdminUser.Role} value
 * @return {!proto.stailer_external_admin.UpdateUserRequest.UpdateRole} returns this
 */
proto.stailer_external_admin.UpdateUserRequest.UpdateRole.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stailer_external_admin.UpdateUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.UpdateUserRequest} returns this
 */
proto.stailer_external_admin.UpdateUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shop_id = 3;
 * @return {string}
 */
proto.stailer_external_admin.UpdateUserRequest.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.UpdateUserRequest} returns this
 */
proto.stailer_external_admin.UpdateUserRequest.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UpdateRole update_role = 4;
 * @return {?proto.stailer_external_admin.UpdateUserRequest.UpdateRole}
 */
proto.stailer_external_admin.UpdateUserRequest.prototype.getUpdateRole = function() {
  return /** @type{?proto.stailer_external_admin.UpdateUserRequest.UpdateRole} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.UpdateUserRequest.UpdateRole, 4));
};


/**
 * @param {?proto.stailer_external_admin.UpdateUserRequest.UpdateRole|undefined} value
 * @return {!proto.stailer_external_admin.UpdateUserRequest} returns this
*/
proto.stailer_external_admin.UpdateUserRequest.prototype.setUpdateRole = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.UpdateUserRequest} returns this
 */
proto.stailer_external_admin.UpdateUserRequest.prototype.clearUpdateRole = function() {
  return this.setUpdateRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.UpdateUserRequest.prototype.hasUpdateRole = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.UpdateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.UpdateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.UpdateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalAdminUser: (f = msg.getExternalAdminUser()) && stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UpdateUserResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.UpdateUserResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.externalAdminUser;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.UpdateUserResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.UpdateUserResponse}
 */
proto.stailer_external_admin.UpdateUserResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.UpdateUserResponse();
  obj.externalAdminUser && jspb.Message.setWrapperField(
      msg, 1, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.fromObject(obj.externalAdminUser));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.UpdateUserResponse}
 */
proto.stailer_external_admin.UpdateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.UpdateUserResponse;
  return proto.stailer_external_admin.UpdateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.UpdateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.UpdateUserResponse}
 */
proto.stailer_external_admin.UpdateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser;
      reader.readMessage(value,stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.deserializeBinaryFromReader);
      msg.setExternalAdminUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.UpdateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.UpdateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.UpdateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalAdminUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalAdminUser external_admin_user = 1;
 * @return {?proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.UpdateUserResponse.prototype.getExternalAdminUser = function() {
  return /** @type{?proto.stailer_external_admin.ExternalAdminUser} */ (
    jspb.Message.getWrapperField(this, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser, 1));
};


/**
 * @param {?proto.stailer_external_admin.ExternalAdminUser|undefined} value
 * @return {!proto.stailer_external_admin.UpdateUserResponse} returns this
*/
proto.stailer_external_admin.UpdateUserResponse.prototype.setExternalAdminUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.UpdateUserResponse} returns this
 */
proto.stailer_external_admin.UpdateUserResponse.prototype.clearExternalAdminUser = function() {
  return this.setExternalAdminUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.UpdateUserResponse.prototype.hasExternalAdminUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.UpdateSelfRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.UpdateSelfRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.UpdateSelfRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateSelfRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UpdateSelfRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.UpdateSelfRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.UpdateSelfRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.UpdateSelfRequest}
 */
proto.stailer_external_admin.UpdateSelfRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.UpdateSelfRequest();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.UpdateSelfRequest}
 */
proto.stailer_external_admin.UpdateSelfRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.UpdateSelfRequest;
  return proto.stailer_external_admin.UpdateSelfRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.UpdateSelfRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.UpdateSelfRequest}
 */
proto.stailer_external_admin.UpdateSelfRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.UpdateSelfRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.UpdateSelfRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.UpdateSelfRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateSelfRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.stailer_external_admin.UpdateSelfRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.UpdateSelfRequest} returns this
 */
proto.stailer_external_admin.UpdateSelfRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.UpdateSelfResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.UpdateSelfResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.UpdateSelfResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateSelfResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalAdminUser: (f = msg.getExternalAdminUser()) && stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UpdateSelfResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.UpdateSelfResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.externalAdminUser;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.UpdateSelfResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.UpdateSelfResponse}
 */
proto.stailer_external_admin.UpdateSelfResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.UpdateSelfResponse();
  obj.externalAdminUser && jspb.Message.setWrapperField(
      msg, 1, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.fromObject(obj.externalAdminUser));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.UpdateSelfResponse}
 */
proto.stailer_external_admin.UpdateSelfResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.UpdateSelfResponse;
  return proto.stailer_external_admin.UpdateSelfResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.UpdateSelfResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.UpdateSelfResponse}
 */
proto.stailer_external_admin.UpdateSelfResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser;
      reader.readMessage(value,stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.deserializeBinaryFromReader);
      msg.setExternalAdminUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.UpdateSelfResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.UpdateSelfResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.UpdateSelfResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateSelfResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalAdminUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalAdminUser external_admin_user = 1;
 * @return {?proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.UpdateSelfResponse.prototype.getExternalAdminUser = function() {
  return /** @type{?proto.stailer_external_admin.ExternalAdminUser} */ (
    jspb.Message.getWrapperField(this, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser, 1));
};


/**
 * @param {?proto.stailer_external_admin.ExternalAdminUser|undefined} value
 * @return {!proto.stailer_external_admin.UpdateSelfResponse} returns this
*/
proto.stailer_external_admin.UpdateSelfResponse.prototype.setExternalAdminUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.UpdateSelfResponse} returns this
 */
proto.stailer_external_admin.UpdateSelfResponse.prototype.clearExternalAdminUser = function() {
  return this.setExternalAdminUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.UpdateSelfResponse.prototype.hasExternalAdminUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shopId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UpdateExternalAdminUserSelectShopRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.shopId;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest();
  obj.shopId != null && jspb.Message.setField(msg, 1, obj.shopId);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest;
  return proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string shop_id = 1;
 * @return {string}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest} returns this
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopRequest.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalAdminUser: (f = msg.getExternalAdminUser()) && stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UpdateExternalAdminUserSelectShopResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.externalAdminUser;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse();
  obj.externalAdminUser && jspb.Message.setWrapperField(
      msg, 1, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.fromObject(obj.externalAdminUser));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse;
  return proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser;
      reader.readMessage(value,stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.deserializeBinaryFromReader);
      msg.setExternalAdminUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalAdminUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalAdminUser external_admin_user = 1;
 * @return {?proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.prototype.getExternalAdminUser = function() {
  return /** @type{?proto.stailer_external_admin.ExternalAdminUser} */ (
    jspb.Message.getWrapperField(this, stailer_externaladminrpc_external_admin_user_pb.ExternalAdminUser, 1));
};


/**
 * @param {?proto.stailer_external_admin.ExternalAdminUser|undefined} value
 * @return {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse} returns this
*/
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.prototype.setExternalAdminUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse} returns this
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.prototype.clearExternalAdminUser = function() {
  return this.setExternalAdminUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.UpdateExternalAdminUserSelectShopResponse.prototype.hasExternalAdminUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.DeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.DeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.DeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.DeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeleteRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.DeleteRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.DeleteRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.DeleteRequest}
 */
proto.stailer_external_admin.DeleteRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.DeleteRequest();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.DeleteRequest}
 */
proto.stailer_external_admin.DeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.DeleteRequest;
  return proto.stailer_external_admin.DeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.DeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.DeleteRequest}
 */
proto.stailer_external_admin.DeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.DeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.DeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.DeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.DeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stailer_external_admin.DeleteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.DeleteRequest} returns this
 */
proto.stailer_external_admin.DeleteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.DeleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.DeleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.DeleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.DeleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeleteResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.DeleteResponse.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.DeleteResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.DeleteResponse}
 */
proto.stailer_external_admin.DeleteResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.DeleteResponse();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.DeleteResponse}
 */
proto.stailer_external_admin.DeleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.DeleteResponse;
  return proto.stailer_external_admin.DeleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.DeleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.DeleteResponse}
 */
proto.stailer_external_admin.DeleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.DeleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.DeleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.DeleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.DeleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.InviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.InviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.InviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.InviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of InviteRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.InviteRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.email;

  /** @type {?|undefined} */
  this.role;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.InviteRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.InviteRequest}
 */
proto.stailer_external_admin.InviteRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.InviteRequest();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.email != null && jspb.Message.setField(msg, 2, obj.email);
  obj.role != null && jspb.Message.setField(msg, 3, obj.role);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.InviteRequest}
 */
proto.stailer_external_admin.InviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.InviteRequest;
  return proto.stailer_external_admin.InviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.InviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.InviteRequest}
 */
proto.stailer_external_admin.InviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {!proto.stailer_external_admin.ExternalAdminUser.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.InviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.InviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.InviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.InviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.stailer_external_admin.InviteRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.InviteRequest} returns this
 */
proto.stailer_external_admin.InviteRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.stailer_external_admin.InviteRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.InviteRequest} returns this
 */
proto.stailer_external_admin.InviteRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ExternalAdminUser.Role role = 3;
 * @return {!proto.stailer_external_admin.ExternalAdminUser.Role}
 */
proto.stailer_external_admin.InviteRequest.prototype.getRole = function() {
  return /** @type {!proto.stailer_external_admin.ExternalAdminUser.Role} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.stailer_external_admin.ExternalAdminUser.Role} value
 * @return {!proto.stailer_external_admin.InviteRequest} returns this
 */
proto.stailer_external_admin.InviteRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.InviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.InviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.InviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.InviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of InviteResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.InviteResponse.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.InviteResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.InviteResponse}
 */
proto.stailer_external_admin.InviteResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.InviteResponse();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.InviteResponse}
 */
proto.stailer_external_admin.InviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.InviteResponse;
  return proto.stailer_external_admin.InviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.InviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.InviteResponse}
 */
proto.stailer_external_admin.InviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.InviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.InviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.InviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.InviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of CreateExternalAdminUserDeviceTokenRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.token;

  /** @type {?|undefined} */
  this.platform;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest();
  obj.token != null && jspb.Message.setField(msg, 1, obj.token);
  obj.platform != null && jspb.Message.setField(msg, 2, obj.platform);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest;
  return proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!proto.type.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest} returns this
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional type.Platform platform = 2;
 * @return {!proto.type.Platform}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.prototype.getPlatform = function() {
  return /** @type {!proto.type.Platform} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.type.Platform} value
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest} returns this
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenRequest.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of CreateExternalAdminUserDeviceTokenResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse;
  return proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.CreateExternalAdminUserDeviceTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.UpdatePushNotificationSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isInStoreArrangedDeliveryEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UpdatePushNotificationSettingsRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.isInStoreArrangedDeliveryEnabled;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.UpdatePushNotificationSettingsRequest}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.UpdatePushNotificationSettingsRequest();
  obj.isInStoreArrangedDeliveryEnabled != null && jspb.Message.setField(msg, 1, obj.isInStoreArrangedDeliveryEnabled);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.UpdatePushNotificationSettingsRequest}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.UpdatePushNotificationSettingsRequest;
  return proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.UpdatePushNotificationSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.UpdatePushNotificationSettingsRequest}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInStoreArrangedDeliveryEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.UpdatePushNotificationSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsInStoreArrangedDeliveryEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_in_store_arranged_delivery_enabled = 1;
 * @return {boolean}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.prototype.getIsInStoreArrangedDeliveryEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.UpdatePushNotificationSettingsRequest} returns this
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsRequest.prototype.setIsInStoreArrangedDeliveryEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.UpdatePushNotificationSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UpdatePushNotificationSettingsResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.UpdatePushNotificationSettingsResponse}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.UpdatePushNotificationSettingsResponse();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.UpdatePushNotificationSettingsResponse}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.UpdatePushNotificationSettingsResponse;
  return proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.UpdatePushNotificationSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.UpdatePushNotificationSettingsResponse}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.UpdatePushNotificationSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.UpdatePushNotificationSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.stailer_external_admin);
