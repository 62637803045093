// source: type/ec_product.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var type_ec_site_pb = require('../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
var type_bundle_type_pb = require('../type/bundle_type_pb.js');
goog.object.extend(proto, type_bundle_type_pb);
var type_date_pb = require('../type/date_pb.js');
goog.object.extend(proto, type_date_pb);
var type_sales_badge_pb = require('../type/sales_badge_pb.js');
goog.object.extend(proto, type_sales_badge_pb);
var type_tax_type_pb = require('../type/tax_type_pb.js');
goog.object.extend(proto, type_tax_type_pb);
var type_price_type_pb = require('../type/price_type_pb.js');
goog.object.extend(proto, type_price_type_pb);
var type_delivery_limit_time_pb = require('../type/delivery_limit_time_pb.js');
goog.object.extend(proto, type_delivery_limit_time_pb);
var type_subscription_type_pb = require('../type/subscription_type_pb.js');
goog.object.extend(proto, type_subscription_type_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var type_interleaving_pb = require('../type/interleaving_pb.js');
goog.object.extend(proto, type_interleaving_pb);
goog.exportSymbol('proto.type.DisplayOnlyPrice', null, global);
goog.exportSymbol('proto.type.DrugRiskClassification', null, global);
goog.exportSymbol('proto.type.EcProduct', null, global);
goog.exportSymbol('proto.type.EcProduct.DeliveryDateSale', null, global);
goog.exportSymbol('proto.type.EcProduct.MedicineDescription', null, global);
goog.exportSymbol('proto.type.EcProduct.ReservationPeriod', null, global);
goog.exportSymbol('proto.type.EcProduct.SaleRequirementCase', null, global);
goog.exportSymbol('proto.type.EcProduct.WeightProductSellingCase', null, global);
goog.exportSymbol('proto.type.EcProductCategory', null, global);
goog.exportSymbol('proto.type.FixedWeightProductSelling', null, global);
goog.exportSymbol('proto.type.SalesAgeRestrictionType', null, global);
goog.exportSymbol('proto.type.TherapeuticCategory', null, global);
goog.exportSymbol('proto.type.VariableWeightProductAcceptableWeight', null, global);
goog.exportSymbol('proto.type.VariableWeightProductPricing', null, global);
goog.exportSymbol('proto.type.VariableWeightProductSelling', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.DisplayOnlyPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.DisplayOnlyPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.DisplayOnlyPrice.displayName = 'proto.type.DisplayOnlyPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.VariableWeightProductPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.VariableWeightProductPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.VariableWeightProductPricing.displayName = 'proto.type.VariableWeightProductPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.VariableWeightProductAcceptableWeight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.VariableWeightProductAcceptableWeight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.VariableWeightProductAcceptableWeight.displayName = 'proto.type.VariableWeightProductAcceptableWeight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.VariableWeightProductSelling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.VariableWeightProductSelling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.VariableWeightProductSelling.displayName = 'proto.type.VariableWeightProductSelling';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.FixedWeightProductSelling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.FixedWeightProductSelling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.FixedWeightProductSelling.displayName = 'proto.type.FixedWeightProductSelling';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.EcProduct.repeatedFields_, proto.type.EcProduct.oneofGroups_);
};
goog.inherits(proto.type.EcProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcProduct.displayName = 'proto.type.EcProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcProduct.DeliveryDateSale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.EcProduct.DeliveryDateSale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcProduct.DeliveryDateSale.displayName = 'proto.type.EcProduct.DeliveryDateSale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcProduct.MedicineDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.EcProduct.MedicineDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcProduct.MedicineDescription.displayName = 'proto.type.EcProduct.MedicineDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcProduct.ReservationPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.EcProduct.ReservationPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcProduct.ReservationPeriod.displayName = 'proto.type.EcProduct.ReservationPeriod';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.DisplayOnlyPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.type.DisplayOnlyPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.DisplayOnlyPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DisplayOnlyPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    approximateTaxIncludedPrice: jspb.Message.getFieldWithDefault(msg, 1, ""),
    approximateTaxExcludedPrice: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DisplayOnlyPrice as accepted by the `fromObject` method.
 * @record
 */
proto.type.DisplayOnlyPrice.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.approximateTaxIncludedPrice;

  /** @type {?|undefined} */
  this.approximateTaxExcludedPrice;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.DisplayOnlyPrice.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.DisplayOnlyPrice}
 */
proto.type.DisplayOnlyPrice.fromObject = function(obj) {
  var msg = new proto.type.DisplayOnlyPrice();
  obj.approximateTaxIncludedPrice != null && jspb.Message.setField(msg, 1, obj.approximateTaxIncludedPrice);
  obj.approximateTaxExcludedPrice != null && jspb.Message.setField(msg, 2, obj.approximateTaxExcludedPrice);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.DisplayOnlyPrice}
 */
proto.type.DisplayOnlyPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.DisplayOnlyPrice;
  return proto.type.DisplayOnlyPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.DisplayOnlyPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.DisplayOnlyPrice}
 */
proto.type.DisplayOnlyPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproximateTaxIncludedPrice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproximateTaxExcludedPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.DisplayOnlyPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.DisplayOnlyPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.DisplayOnlyPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DisplayOnlyPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApproximateTaxIncludedPrice();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApproximateTaxExcludedPrice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string approximate_tax_included_price = 1;
 * @return {string}
 */
proto.type.DisplayOnlyPrice.prototype.getApproximateTaxIncludedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.DisplayOnlyPrice} returns this
 */
proto.type.DisplayOnlyPrice.prototype.setApproximateTaxIncludedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string approximate_tax_excluded_price = 2;
 * @return {string}
 */
proto.type.DisplayOnlyPrice.prototype.getApproximateTaxExcludedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.DisplayOnlyPrice} returns this
 */
proto.type.DisplayOnlyPrice.prototype.setApproximateTaxExcludedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.VariableWeightProductPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.type.VariableWeightProductPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.VariableWeightProductPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.VariableWeightProductPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    medianPrice: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minPrice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxPrice: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of VariableWeightProductPricing as accepted by the `fromObject` method.
 * @record
 */
proto.type.VariableWeightProductPricing.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.medianPrice;

  /** @type {?|undefined} */
  this.minPrice;

  /** @type {?|undefined} */
  this.maxPrice;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.VariableWeightProductPricing.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.VariableWeightProductPricing}
 */
proto.type.VariableWeightProductPricing.fromObject = function(obj) {
  var msg = new proto.type.VariableWeightProductPricing();
  obj.medianPrice != null && jspb.Message.setField(msg, 1, obj.medianPrice);
  obj.minPrice != null && jspb.Message.setField(msg, 2, obj.minPrice);
  obj.maxPrice != null && jspb.Message.setField(msg, 3, obj.maxPrice);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.VariableWeightProductPricing}
 */
proto.type.VariableWeightProductPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.VariableWeightProductPricing;
  return proto.type.VariableWeightProductPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.VariableWeightProductPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.VariableWeightProductPricing}
 */
proto.type.VariableWeightProductPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMedianPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.VariableWeightProductPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.VariableWeightProductPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.VariableWeightProductPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.VariableWeightProductPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedianPrice();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMinPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMaxPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 median_price = 1;
 * @return {number}
 */
proto.type.VariableWeightProductPricing.prototype.getMedianPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.VariableWeightProductPricing} returns this
 */
proto.type.VariableWeightProductPricing.prototype.setMedianPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 min_price = 2;
 * @return {number}
 */
proto.type.VariableWeightProductPricing.prototype.getMinPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.VariableWeightProductPricing} returns this
 */
proto.type.VariableWeightProductPricing.prototype.setMinPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 max_price = 3;
 * @return {number}
 */
proto.type.VariableWeightProductPricing.prototype.getMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.VariableWeightProductPricing} returns this
 */
proto.type.VariableWeightProductPricing.prototype.setMaxPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.VariableWeightProductAcceptableWeight.prototype.toObject = function(opt_includeInstance) {
  return proto.type.VariableWeightProductAcceptableWeight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.VariableWeightProductAcceptableWeight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.VariableWeightProductAcceptableWeight.toObject = function(includeInstance, msg) {
  var f, obj = {
    standardWeight: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxWeight: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minWeight: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of VariableWeightProductAcceptableWeight as accepted by the `fromObject` method.
 * @record
 */
proto.type.VariableWeightProductAcceptableWeight.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.standardWeight;

  /** @type {?|undefined} */
  this.maxWeight;

  /** @type {?|undefined} */
  this.minWeight;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.VariableWeightProductAcceptableWeight.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.VariableWeightProductAcceptableWeight}
 */
proto.type.VariableWeightProductAcceptableWeight.fromObject = function(obj) {
  var msg = new proto.type.VariableWeightProductAcceptableWeight();
  obj.standardWeight != null && jspb.Message.setField(msg, 1, obj.standardWeight);
  obj.maxWeight != null && jspb.Message.setField(msg, 2, obj.maxWeight);
  obj.minWeight != null && jspb.Message.setField(msg, 3, obj.minWeight);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.VariableWeightProductAcceptableWeight}
 */
proto.type.VariableWeightProductAcceptableWeight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.VariableWeightProductAcceptableWeight;
  return proto.type.VariableWeightProductAcceptableWeight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.VariableWeightProductAcceptableWeight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.VariableWeightProductAcceptableWeight}
 */
proto.type.VariableWeightProductAcceptableWeight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStandardWeight(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxWeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.VariableWeightProductAcceptableWeight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.VariableWeightProductAcceptableWeight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.VariableWeightProductAcceptableWeight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.VariableWeightProductAcceptableWeight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStandardWeight();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMaxWeight();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMinWeight();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 standard_weight = 1;
 * @return {number}
 */
proto.type.VariableWeightProductAcceptableWeight.prototype.getStandardWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.VariableWeightProductAcceptableWeight} returns this
 */
proto.type.VariableWeightProductAcceptableWeight.prototype.setStandardWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 max_weight = 2;
 * @return {number}
 */
proto.type.VariableWeightProductAcceptableWeight.prototype.getMaxWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.VariableWeightProductAcceptableWeight} returns this
 */
proto.type.VariableWeightProductAcceptableWeight.prototype.setMaxWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 min_weight = 3;
 * @return {number}
 */
proto.type.VariableWeightProductAcceptableWeight.prototype.getMinWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.VariableWeightProductAcceptableWeight} returns this
 */
proto.type.VariableWeightProductAcceptableWeight.prototype.setMinWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.VariableWeightProductSelling.prototype.toObject = function(opt_includeInstance) {
  return proto.type.VariableWeightProductSelling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.VariableWeightProductSelling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.VariableWeightProductSelling.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricing: (f = msg.getPricing()) && proto.type.VariableWeightProductPricing.toObject(includeInstance, f),
    acceptableWeight: (f = msg.getAcceptableWeight()) && proto.type.VariableWeightProductAcceptableWeight.toObject(includeInstance, f),
    displayOnlyMedianPrice: (f = msg.getDisplayOnlyMedianPrice()) && proto.type.DisplayOnlyPrice.toObject(includeInstance, f),
    displayOnlyMaxPrice: (f = msg.getDisplayOnlyMaxPrice()) && proto.type.DisplayOnlyPrice.toObject(includeInstance, f),
    displayOnlyMinPrice: (f = msg.getDisplayOnlyMinPrice()) && proto.type.DisplayOnlyPrice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of VariableWeightProductSelling as accepted by the `fromObject` method.
 * @record
 */
proto.type.VariableWeightProductSelling.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.pricing;

  /** @type {?|undefined} */
  this.acceptableWeight;

  /** @type {?|undefined} */
  this.displayOnlyMedianPrice;

  /** @type {?|undefined} */
  this.displayOnlyMaxPrice;

  /** @type {?|undefined} */
  this.displayOnlyMinPrice;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.VariableWeightProductSelling.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.VariableWeightProductSelling}
 */
proto.type.VariableWeightProductSelling.fromObject = function(obj) {
  var msg = new proto.type.VariableWeightProductSelling();
  obj.pricing && jspb.Message.setWrapperField(
      msg, 1, proto.type.VariableWeightProductPricing.fromObject(obj.pricing));
  obj.acceptableWeight && jspb.Message.setWrapperField(
      msg, 2, proto.type.VariableWeightProductAcceptableWeight.fromObject(obj.acceptableWeight));
  obj.displayOnlyMedianPrice && jspb.Message.setWrapperField(
      msg, 3, proto.type.DisplayOnlyPrice.fromObject(obj.displayOnlyMedianPrice));
  obj.displayOnlyMaxPrice && jspb.Message.setWrapperField(
      msg, 4, proto.type.DisplayOnlyPrice.fromObject(obj.displayOnlyMaxPrice));
  obj.displayOnlyMinPrice && jspb.Message.setWrapperField(
      msg, 5, proto.type.DisplayOnlyPrice.fromObject(obj.displayOnlyMinPrice));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.VariableWeightProductSelling}
 */
proto.type.VariableWeightProductSelling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.VariableWeightProductSelling;
  return proto.type.VariableWeightProductSelling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.VariableWeightProductSelling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.VariableWeightProductSelling}
 */
proto.type.VariableWeightProductSelling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.VariableWeightProductPricing;
      reader.readMessage(value,proto.type.VariableWeightProductPricing.deserializeBinaryFromReader);
      msg.setPricing(value);
      break;
    case 2:
      var value = new proto.type.VariableWeightProductAcceptableWeight;
      reader.readMessage(value,proto.type.VariableWeightProductAcceptableWeight.deserializeBinaryFromReader);
      msg.setAcceptableWeight(value);
      break;
    case 3:
      var value = new proto.type.DisplayOnlyPrice;
      reader.readMessage(value,proto.type.DisplayOnlyPrice.deserializeBinaryFromReader);
      msg.setDisplayOnlyMedianPrice(value);
      break;
    case 4:
      var value = new proto.type.DisplayOnlyPrice;
      reader.readMessage(value,proto.type.DisplayOnlyPrice.deserializeBinaryFromReader);
      msg.setDisplayOnlyMaxPrice(value);
      break;
    case 5:
      var value = new proto.type.DisplayOnlyPrice;
      reader.readMessage(value,proto.type.DisplayOnlyPrice.deserializeBinaryFromReader);
      msg.setDisplayOnlyMinPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.VariableWeightProductSelling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.VariableWeightProductSelling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.VariableWeightProductSelling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.VariableWeightProductSelling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.VariableWeightProductPricing.serializeBinaryToWriter
    );
  }
  f = message.getAcceptableWeight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.type.VariableWeightProductAcceptableWeight.serializeBinaryToWriter
    );
  }
  f = message.getDisplayOnlyMedianPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.type.DisplayOnlyPrice.serializeBinaryToWriter
    );
  }
  f = message.getDisplayOnlyMaxPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.type.DisplayOnlyPrice.serializeBinaryToWriter
    );
  }
  f = message.getDisplayOnlyMinPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.type.DisplayOnlyPrice.serializeBinaryToWriter
    );
  }
};


/**
 * optional VariableWeightProductPricing pricing = 1;
 * @return {?proto.type.VariableWeightProductPricing}
 */
proto.type.VariableWeightProductSelling.prototype.getPricing = function() {
  return /** @type{?proto.type.VariableWeightProductPricing} */ (
    jspb.Message.getWrapperField(this, proto.type.VariableWeightProductPricing, 1));
};


/**
 * @param {?proto.type.VariableWeightProductPricing|undefined} value
 * @return {!proto.type.VariableWeightProductSelling} returns this
*/
proto.type.VariableWeightProductSelling.prototype.setPricing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.VariableWeightProductSelling} returns this
 */
proto.type.VariableWeightProductSelling.prototype.clearPricing = function() {
  return this.setPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.VariableWeightProductSelling.prototype.hasPricing = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional VariableWeightProductAcceptableWeight acceptable_weight = 2;
 * @return {?proto.type.VariableWeightProductAcceptableWeight}
 */
proto.type.VariableWeightProductSelling.prototype.getAcceptableWeight = function() {
  return /** @type{?proto.type.VariableWeightProductAcceptableWeight} */ (
    jspb.Message.getWrapperField(this, proto.type.VariableWeightProductAcceptableWeight, 2));
};


/**
 * @param {?proto.type.VariableWeightProductAcceptableWeight|undefined} value
 * @return {!proto.type.VariableWeightProductSelling} returns this
*/
proto.type.VariableWeightProductSelling.prototype.setAcceptableWeight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.VariableWeightProductSelling} returns this
 */
proto.type.VariableWeightProductSelling.prototype.clearAcceptableWeight = function() {
  return this.setAcceptableWeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.VariableWeightProductSelling.prototype.hasAcceptableWeight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DisplayOnlyPrice display_only_median_price = 3;
 * @return {?proto.type.DisplayOnlyPrice}
 */
proto.type.VariableWeightProductSelling.prototype.getDisplayOnlyMedianPrice = function() {
  return /** @type{?proto.type.DisplayOnlyPrice} */ (
    jspb.Message.getWrapperField(this, proto.type.DisplayOnlyPrice, 3));
};


/**
 * @param {?proto.type.DisplayOnlyPrice|undefined} value
 * @return {!proto.type.VariableWeightProductSelling} returns this
*/
proto.type.VariableWeightProductSelling.prototype.setDisplayOnlyMedianPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.VariableWeightProductSelling} returns this
 */
proto.type.VariableWeightProductSelling.prototype.clearDisplayOnlyMedianPrice = function() {
  return this.setDisplayOnlyMedianPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.VariableWeightProductSelling.prototype.hasDisplayOnlyMedianPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DisplayOnlyPrice display_only_max_price = 4;
 * @return {?proto.type.DisplayOnlyPrice}
 */
proto.type.VariableWeightProductSelling.prototype.getDisplayOnlyMaxPrice = function() {
  return /** @type{?proto.type.DisplayOnlyPrice} */ (
    jspb.Message.getWrapperField(this, proto.type.DisplayOnlyPrice, 4));
};


/**
 * @param {?proto.type.DisplayOnlyPrice|undefined} value
 * @return {!proto.type.VariableWeightProductSelling} returns this
*/
proto.type.VariableWeightProductSelling.prototype.setDisplayOnlyMaxPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.VariableWeightProductSelling} returns this
 */
proto.type.VariableWeightProductSelling.prototype.clearDisplayOnlyMaxPrice = function() {
  return this.setDisplayOnlyMaxPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.VariableWeightProductSelling.prototype.hasDisplayOnlyMaxPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DisplayOnlyPrice display_only_min_price = 5;
 * @return {?proto.type.DisplayOnlyPrice}
 */
proto.type.VariableWeightProductSelling.prototype.getDisplayOnlyMinPrice = function() {
  return /** @type{?proto.type.DisplayOnlyPrice} */ (
    jspb.Message.getWrapperField(this, proto.type.DisplayOnlyPrice, 5));
};


/**
 * @param {?proto.type.DisplayOnlyPrice|undefined} value
 * @return {!proto.type.VariableWeightProductSelling} returns this
*/
proto.type.VariableWeightProductSelling.prototype.setDisplayOnlyMinPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.VariableWeightProductSelling} returns this
 */
proto.type.VariableWeightProductSelling.prototype.clearDisplayOnlyMinPrice = function() {
  return this.setDisplayOnlyMinPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.VariableWeightProductSelling.prototype.hasDisplayOnlyMinPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.FixedWeightProductSelling.prototype.toObject = function(opt_includeInstance) {
  return proto.type.FixedWeightProductSelling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.FixedWeightProductSelling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.FixedWeightProductSelling.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayOnlyPrice: (f = msg.getDisplayOnlyPrice()) && proto.type.DisplayOnlyPrice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of FixedWeightProductSelling as accepted by the `fromObject` method.
 * @record
 */
proto.type.FixedWeightProductSelling.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.displayOnlyPrice;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.FixedWeightProductSelling.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.FixedWeightProductSelling}
 */
proto.type.FixedWeightProductSelling.fromObject = function(obj) {
  var msg = new proto.type.FixedWeightProductSelling();
  obj.displayOnlyPrice && jspb.Message.setWrapperField(
      msg, 1, proto.type.DisplayOnlyPrice.fromObject(obj.displayOnlyPrice));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.FixedWeightProductSelling}
 */
proto.type.FixedWeightProductSelling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.FixedWeightProductSelling;
  return proto.type.FixedWeightProductSelling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.FixedWeightProductSelling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.FixedWeightProductSelling}
 */
proto.type.FixedWeightProductSelling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.DisplayOnlyPrice;
      reader.readMessage(value,proto.type.DisplayOnlyPrice.deserializeBinaryFromReader);
      msg.setDisplayOnlyPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.FixedWeightProductSelling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.FixedWeightProductSelling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.FixedWeightProductSelling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.FixedWeightProductSelling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayOnlyPrice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.DisplayOnlyPrice.serializeBinaryToWriter
    );
  }
};


/**
 * optional DisplayOnlyPrice display_only_price = 1;
 * @return {?proto.type.DisplayOnlyPrice}
 */
proto.type.FixedWeightProductSelling.prototype.getDisplayOnlyPrice = function() {
  return /** @type{?proto.type.DisplayOnlyPrice} */ (
    jspb.Message.getWrapperField(this, proto.type.DisplayOnlyPrice, 1));
};


/**
 * @param {?proto.type.DisplayOnlyPrice|undefined} value
 * @return {!proto.type.FixedWeightProductSelling} returns this
*/
proto.type.FixedWeightProductSelling.prototype.setDisplayOnlyPrice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.FixedWeightProductSelling} returns this
 */
proto.type.FixedWeightProductSelling.prototype.clearDisplayOnlyPrice = function() {
  return this.setDisplayOnlyPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.FixedWeightProductSelling.prototype.hasDisplayOnlyPrice = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.EcProduct.repeatedFields_ = [19,33,35,61];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.type.EcProduct.oneofGroups_ = [[27],[70,71]];

/**
 * @enum {number}
 */
proto.type.EcProduct.SaleRequirementCase = {
  SALE_REQUIREMENT_NOT_SET: 0,
  DELIVERY_DATE_SALE: 27
};

/**
 * @return {proto.type.EcProduct.SaleRequirementCase}
 */
proto.type.EcProduct.prototype.getSaleRequirementCase = function() {
  return /** @type {proto.type.EcProduct.SaleRequirementCase} */(jspb.Message.computeOneofCase(this, proto.type.EcProduct.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.type.EcProduct.WeightProductSellingCase = {
  WEIGHT_PRODUCT_SELLING_NOT_SET: 0,
  VARIABLE_WEIGHT_PRODUCT_SELLING: 70,
  FIXED_WEIGHT_PRODUCT_SELLING: 71
};

/**
 * @return {proto.type.EcProduct.WeightProductSellingCase}
 */
proto.type.EcProduct.prototype.getWeightProductSellingCase = function() {
  return /** @type {proto.type.EcProduct.WeightProductSellingCase} */(jspb.Message.computeOneofCase(this, proto.type.EcProduct.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    url: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deprecatedInTaxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    noTaxPrice: jspb.Message.getFieldWithDefault(msg, 7, 0),
    catalogId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    inTaxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    isUnavailable: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    taxRate: jspb.Message.getFieldWithDefault(msg, 11, 0),
    deprecatedTaxType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    isLiked: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    deliveryLimitDateFrom: (f = msg.getDeliveryLimitDateFrom()) && type_date_pb.Date.toObject(includeInstance, f),
    deliveryLimitDateTo: (f = msg.getDeliveryLimitDateTo()) && type_date_pb.Date.toObject(includeInstance, f),
    detail: jspb.Message.getFieldWithDefault(msg, 16, ""),
    unitPriceText: jspb.Message.getFieldWithDefault(msg, 17, ""),
    from: jspb.Message.getFieldWithDefault(msg, 18, ""),
    otherImageUrlsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    quantityLimit: jspb.Message.getFieldWithDefault(msg, 21, 0),
    isMedical: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    drugRiskClassification: jspb.Message.getFieldWithDefault(msg, 23, 0),
    brandName: jspb.Message.getFieldWithDefault(msg, 24, ""),
    isCounselingRequired: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    pointInfo: jspb.Message.getFieldWithDefault(msg, 26, ""),
    deliveryDateSale: (f = msg.getDeliveryDateSale()) && proto.type.EcProduct.DeliveryDateSale.toObject(includeInstance, f),
    amount: jspb.Message.getFieldWithDefault(msg, 28, 0),
    groupKey: jspb.Message.getFieldWithDefault(msg, 29, ""),
    hasGroupInfo: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    colorName: jspb.Message.getFieldWithDefault(msg, 31, ""),
    sizeName: jspb.Message.getFieldWithDefault(msg, 32, ""),
    brandNamesList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
    displayBrandName: jspb.Message.getFieldWithDefault(msg, 34, ""),
    deliveryLimitTimesList: jspb.Message.toObjectList(msg.getDeliveryLimitTimesList(),
    type_delivery_limit_time_pb.DeliveryLimitTime.toObject, includeInstance),
    isSelfMedication: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    salesAgeRestrictionType: jspb.Message.getFieldWithDefault(msg, 37, 0),
    hasNotice: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    isPcrKit: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    taxType: jspb.Message.getFieldWithDefault(msg, 41, 0),
    isReducedTaxRate: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    isExcludedEarnedPoint: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    price: jspb.Message.getFieldWithDefault(msg, 44, 0),
    priceType: jspb.Message.getFieldWithDefault(msg, 45, 0),
    isInSaleCategory: jspb.Message.getBooleanFieldWithDefault(msg, 46, false),
    isAlcohol: jspb.Message.getBooleanFieldWithDefault(msg, 47, false),
    isEmphasizedPrice: jspb.Message.getBooleanFieldWithDefault(msg, 48, false),
    subscriptionType: jspb.Message.getFieldWithDefault(msg, 49, 0),
    medicineDescription: (f = msg.getMedicineDescription()) && proto.type.EcProduct.MedicineDescription.toObject(includeInstance, f),
    canDeliveryUnattended: jspb.Message.getBooleanFieldWithDefault(msg, 51, false),
    salesBadge: (f = msg.getSalesBadge()) && type_sales_badge_pb.SalesBadge.toObject(includeInstance, f),
    bundleType: jspb.Message.getFieldWithDefault(msg, 53, 0),
    bundleLabel: jspb.Message.getFieldWithDefault(msg, 54, ""),
    bundleRuleId: jspb.Message.getFieldWithDefault(msg, 55, ""),
    bundleRuleMinimumThreshold: jspb.Message.getFieldWithDefault(msg, 56, 0),
    bundleRuleMinimumPrice: jspb.Message.getFieldWithDefault(msg, 57, 0),
    bundleRulePriceType: jspb.Message.getFieldWithDefault(msg, 58, 0),
    bundleName: jspb.Message.getFieldWithDefault(msg, 59, ""),
    bundleRuleSnapshotId: jspb.Message.getFieldWithDefault(msg, 60, ""),
    reservationPeriodsList: jspb.Message.toObjectList(msg.getReservationPeriodsList(),
    proto.type.EcProduct.ReservationPeriod.toObject, includeInstance),
    isReservationProduct: jspb.Message.getBooleanFieldWithDefault(msg, 62, false),
    approximateTaxIncludedPrice: jspb.Message.getFieldWithDefault(msg, 63, ""),
    approximateTaxExcludedPrice: jspb.Message.getFieldWithDefault(msg, 64, ""),
    productNameKana: jspb.Message.getFieldWithDefault(msg, 65, ""),
    isRecurringOrderProduct: jspb.Message.getBooleanFieldWithDefault(msg, 66, false),
    interleaving: (f = msg.getInterleaving()) && type_interleaving_pb.Interleaving.toObject(includeInstance, f),
    unitPricePer100g: jspb.Message.getFieldWithDefault(msg, 68, 0),
    weightLabel: jspb.Message.getFieldWithDefault(msg, 69, ""),
    variableWeightProductSelling: (f = msg.getVariableWeightProductSelling()) && proto.type.VariableWeightProductSelling.toObject(includeInstance, f),
    fixedWeightProductSelling: (f = msg.getFixedWeightProductSelling()) && proto.type.FixedWeightProductSelling.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcProduct as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcProduct.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.productName;

  /** @type {?|undefined} */
  this.url;

  /** @type {?|undefined} */
  this.imageUrl;

  /** @type {?|undefined} */
  this.deprecatedInTaxPrice;

  /** @type {?|undefined} */
  this.noTaxPrice;

  /** @type {?|undefined} */
  this.catalogId;

  /** @type {?|undefined} */
  this.inTaxPrice;

  /** @type {?|undefined} */
  this.isUnavailable;

  /** @type {?|undefined} */
  this.taxRate;

  /** @type {?|undefined} */
  this.deprecatedTaxType;

  /** @type {?|undefined} */
  this.isLiked;

  /** @type {?|undefined} */
  this.deliveryLimitDateFrom;

  /** @type {?|undefined} */
  this.deliveryLimitDateTo;

  /** @type {?|undefined} */
  this.detail;

  /** @type {?|undefined} */
  this.unitPriceText;

  /** @type {?|undefined} */
  this.from;

  /** @type {?|undefined} */
  this.otherImageUrlsList;

  /** @type {?|undefined} */
  this.isFavorite;

  /** @type {?|undefined} */
  this.quantityLimit;

  /** @type {?|undefined} */
  this.isMedical;

  /** @type {?|undefined} */
  this.drugRiskClassification;

  /** @type {?|undefined} */
  this.brandName;

  /** @type {?|undefined} */
  this.isCounselingRequired;

  /** @type {?|undefined} */
  this.pointInfo;

  /** @type {?|undefined} */
  this.deliveryDateSale;

  /** @type {?|undefined} */
  this.amount;

  /** @type {?|undefined} */
  this.groupKey;

  /** @type {?|undefined} */
  this.hasGroupInfo;

  /** @type {?|undefined} */
  this.colorName;

  /** @type {?|undefined} */
  this.sizeName;

  /** @type {?|undefined} */
  this.brandNamesList;

  /** @type {?|undefined} */
  this.displayBrandName;

  /** @type {?|undefined} */
  this.deliveryLimitTimesList;

  /** @type {?|undefined} */
  this.isSelfMedication;

  /** @type {?|undefined} */
  this.salesAgeRestrictionType;

  /** @type {?|undefined} */
  this.hasNotice;

  /** @type {?|undefined} */
  this.isPcrKit;

  /** @type {?|undefined} */
  this.taxType;

  /** @type {?|undefined} */
  this.isReducedTaxRate;

  /** @type {?|undefined} */
  this.isExcludedEarnedPoint;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.priceType;

  /** @type {?|undefined} */
  this.isInSaleCategory;

  /** @type {?|undefined} */
  this.isAlcohol;

  /** @type {?|undefined} */
  this.isEmphasizedPrice;

  /** @type {?|undefined} */
  this.subscriptionType;

  /** @type {?|undefined} */
  this.medicineDescription;

  /** @type {?|undefined} */
  this.canDeliveryUnattended;

  /** @type {?|undefined} */
  this.salesBadge;

  /** @type {?|undefined} */
  this.bundleType;

  /** @type {?|undefined} */
  this.bundleLabel;

  /** @type {?|undefined} */
  this.bundleRuleId;

  /** @type {?|undefined} */
  this.bundleRuleMinimumThreshold;

  /** @type {?|undefined} */
  this.bundleRuleMinimumPrice;

  /** @type {?|undefined} */
  this.bundleRulePriceType;

  /** @type {?|undefined} */
  this.bundleName;

  /** @type {?|undefined} */
  this.bundleRuleSnapshotId;

  /** @type {?|undefined} */
  this.reservationPeriodsList;

  /** @type {?|undefined} */
  this.isReservationProduct;

  /** @type {?|undefined} */
  this.approximateTaxIncludedPrice;

  /** @type {?|undefined} */
  this.approximateTaxExcludedPrice;

  /** @type {?|undefined} */
  this.productNameKana;

  /** @type {?|undefined} */
  this.isRecurringOrderProduct;

  /** @type {?|undefined} */
  this.interleaving;

  /** @type {?|undefined} */
  this.unitPricePer100g;

  /** @type {?|undefined} */
  this.weightLabel;

  /** @type {?|undefined} */
  this.variableWeightProductSelling;

  /** @type {?|undefined} */
  this.fixedWeightProductSelling;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcProduct.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcProduct}
 */
proto.type.EcProduct.fromObject = function(obj) {
  var msg = new proto.type.EcProduct();
  obj.siteType != null && jspb.Message.setField(msg, 1, obj.siteType);
  obj.productId != null && jspb.Message.setField(msg, 2, obj.productId);
  obj.productName != null && jspb.Message.setField(msg, 3, obj.productName);
  obj.url != null && jspb.Message.setField(msg, 4, obj.url);
  obj.imageUrl != null && jspb.Message.setField(msg, 5, obj.imageUrl);
  obj.deprecatedInTaxPrice != null && jspb.Message.setField(msg, 6, obj.deprecatedInTaxPrice);
  obj.noTaxPrice != null && jspb.Message.setField(msg, 7, obj.noTaxPrice);
  obj.catalogId != null && jspb.Message.setField(msg, 8, obj.catalogId);
  obj.inTaxPrice != null && jspb.Message.setField(msg, 9, obj.inTaxPrice);
  obj.isUnavailable != null && jspb.Message.setField(msg, 10, obj.isUnavailable);
  obj.taxRate != null && jspb.Message.setField(msg, 11, obj.taxRate);
  obj.deprecatedTaxType != null && jspb.Message.setField(msg, 12, obj.deprecatedTaxType);
  obj.isLiked != null && jspb.Message.setField(msg, 13, obj.isLiked);
  obj.deliveryLimitDateFrom && jspb.Message.setWrapperField(
      msg, 14, type_date_pb.Date.fromObject(obj.deliveryLimitDateFrom));
  obj.deliveryLimitDateTo && jspb.Message.setWrapperField(
      msg, 15, type_date_pb.Date.fromObject(obj.deliveryLimitDateTo));
  obj.detail != null && jspb.Message.setField(msg, 16, obj.detail);
  obj.unitPriceText != null && jspb.Message.setField(msg, 17, obj.unitPriceText);
  obj.from != null && jspb.Message.setField(msg, 18, obj.from);
  obj.otherImageUrlsList != null && jspb.Message.setField(msg, 19, obj.otherImageUrlsList);
  obj.isFavorite != null && jspb.Message.setField(msg, 20, obj.isFavorite);
  obj.quantityLimit != null && jspb.Message.setField(msg, 21, obj.quantityLimit);
  obj.isMedical != null && jspb.Message.setField(msg, 22, obj.isMedical);
  obj.drugRiskClassification != null && jspb.Message.setField(msg, 23, obj.drugRiskClassification);
  obj.brandName != null && jspb.Message.setField(msg, 24, obj.brandName);
  obj.isCounselingRequired != null && jspb.Message.setField(msg, 25, obj.isCounselingRequired);
  obj.pointInfo != null && jspb.Message.setField(msg, 26, obj.pointInfo);
  obj.deliveryDateSale && jspb.Message.setWrapperField(
      msg, 27, proto.type.EcProduct.DeliveryDateSale.fromObject(obj.deliveryDateSale));
  obj.amount != null && jspb.Message.setField(msg, 28, obj.amount);
  obj.groupKey != null && jspb.Message.setField(msg, 29, obj.groupKey);
  obj.hasGroupInfo != null && jspb.Message.setField(msg, 30, obj.hasGroupInfo);
  obj.colorName != null && jspb.Message.setField(msg, 31, obj.colorName);
  obj.sizeName != null && jspb.Message.setField(msg, 32, obj.sizeName);
  obj.brandNamesList != null && jspb.Message.setField(msg, 33, obj.brandNamesList);
  obj.displayBrandName != null && jspb.Message.setField(msg, 34, obj.displayBrandName);
  obj.deliveryLimitTimesList && jspb.Message.setRepeatedWrapperField(
      msg, 35, obj.deliveryLimitTimesList.map(
          type_delivery_limit_time_pb.DeliveryLimitTime.fromObject));
  obj.isSelfMedication != null && jspb.Message.setField(msg, 36, obj.isSelfMedication);
  obj.salesAgeRestrictionType != null && jspb.Message.setField(msg, 37, obj.salesAgeRestrictionType);
  obj.hasNotice != null && jspb.Message.setField(msg, 39, obj.hasNotice);
  obj.isPcrKit != null && jspb.Message.setField(msg, 40, obj.isPcrKit);
  obj.taxType != null && jspb.Message.setField(msg, 41, obj.taxType);
  obj.isReducedTaxRate != null && jspb.Message.setField(msg, 42, obj.isReducedTaxRate);
  obj.isExcludedEarnedPoint != null && jspb.Message.setField(msg, 43, obj.isExcludedEarnedPoint);
  obj.price != null && jspb.Message.setField(msg, 44, obj.price);
  obj.priceType != null && jspb.Message.setField(msg, 45, obj.priceType);
  obj.isInSaleCategory != null && jspb.Message.setField(msg, 46, obj.isInSaleCategory);
  obj.isAlcohol != null && jspb.Message.setField(msg, 47, obj.isAlcohol);
  obj.isEmphasizedPrice != null && jspb.Message.setField(msg, 48, obj.isEmphasizedPrice);
  obj.subscriptionType != null && jspb.Message.setField(msg, 49, obj.subscriptionType);
  obj.medicineDescription && jspb.Message.setWrapperField(
      msg, 50, proto.type.EcProduct.MedicineDescription.fromObject(obj.medicineDescription));
  obj.canDeliveryUnattended != null && jspb.Message.setField(msg, 51, obj.canDeliveryUnattended);
  obj.salesBadge && jspb.Message.setWrapperField(
      msg, 52, type_sales_badge_pb.SalesBadge.fromObject(obj.salesBadge));
  obj.bundleType != null && jspb.Message.setField(msg, 53, obj.bundleType);
  obj.bundleLabel != null && jspb.Message.setField(msg, 54, obj.bundleLabel);
  obj.bundleRuleId != null && jspb.Message.setField(msg, 55, obj.bundleRuleId);
  obj.bundleRuleMinimumThreshold != null && jspb.Message.setField(msg, 56, obj.bundleRuleMinimumThreshold);
  obj.bundleRuleMinimumPrice != null && jspb.Message.setField(msg, 57, obj.bundleRuleMinimumPrice);
  obj.bundleRulePriceType != null && jspb.Message.setField(msg, 58, obj.bundleRulePriceType);
  obj.bundleName != null && jspb.Message.setField(msg, 59, obj.bundleName);
  obj.bundleRuleSnapshotId != null && jspb.Message.setField(msg, 60, obj.bundleRuleSnapshotId);
  obj.reservationPeriodsList && jspb.Message.setRepeatedWrapperField(
      msg, 61, obj.reservationPeriodsList.map(
          proto.type.EcProduct.ReservationPeriod.fromObject));
  obj.isReservationProduct != null && jspb.Message.setField(msg, 62, obj.isReservationProduct);
  obj.approximateTaxIncludedPrice != null && jspb.Message.setField(msg, 63, obj.approximateTaxIncludedPrice);
  obj.approximateTaxExcludedPrice != null && jspb.Message.setField(msg, 64, obj.approximateTaxExcludedPrice);
  obj.productNameKana != null && jspb.Message.setField(msg, 65, obj.productNameKana);
  obj.isRecurringOrderProduct != null && jspb.Message.setField(msg, 66, obj.isRecurringOrderProduct);
  obj.interleaving && jspb.Message.setWrapperField(
      msg, 67, type_interleaving_pb.Interleaving.fromObject(obj.interleaving));
  obj.unitPricePer100g != null && jspb.Message.setField(msg, 68, obj.unitPricePer100g);
  obj.weightLabel != null && jspb.Message.setField(msg, 69, obj.weightLabel);
  obj.variableWeightProductSelling && jspb.Message.setWrapperField(
      msg, 70, proto.type.VariableWeightProductSelling.fromObject(obj.variableWeightProductSelling));
  obj.fixedWeightProductSelling && jspb.Message.setWrapperField(
      msg, 71, proto.type.FixedWeightProductSelling.fromObject(obj.fixedWeightProductSelling));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcProduct}
 */
proto.type.EcProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcProduct;
  return proto.type.EcProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcProduct}
 */
proto.type.EcProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeprecatedInTaxPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoTaxPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCatalogId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxPrice(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUnavailable(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaxRate(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeprecatedTaxType(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLiked(value);
      break;
    case 14:
      var value = new type_date_pb.Date;
      reader.readMessage(value,type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDeliveryLimitDateFrom(value);
      break;
    case 15:
      var value = new type_date_pb.Date;
      reader.readMessage(value,type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDeliveryLimitDateTo(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitPriceText(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addOtherImageUrls(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavorite(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantityLimit(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMedical(value);
      break;
    case 23:
      var value = /** @type {!proto.type.DrugRiskClassification} */ (reader.readEnum());
      msg.setDrugRiskClassification(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandName(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCounselingRequired(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointInfo(value);
      break;
    case 27:
      var value = new proto.type.EcProduct.DeliveryDateSale;
      reader.readMessage(value,proto.type.EcProduct.DeliveryDateSale.deserializeBinaryFromReader);
      msg.setDeliveryDateSale(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupKey(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasGroupInfo(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorName(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setSizeName(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.addBrandNames(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayBrandName(value);
      break;
    case 35:
      var value = new type_delivery_limit_time_pb.DeliveryLimitTime;
      reader.readMessage(value,type_delivery_limit_time_pb.DeliveryLimitTime.deserializeBinaryFromReader);
      msg.addDeliveryLimitTimes(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSelfMedication(value);
      break;
    case 37:
      var value = /** @type {!proto.type.SalesAgeRestrictionType} */ (reader.readEnum());
      msg.setSalesAgeRestrictionType(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasNotice(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPcrKit(value);
      break;
    case 41:
      var value = /** @type {!proto.type.TaxType} */ (reader.readEnum());
      msg.setTaxType(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReducedTaxRate(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExcludedEarnedPoint(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 45:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setPriceType(value);
      break;
    case 46:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInSaleCategory(value);
      break;
    case 47:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAlcohol(value);
      break;
    case 48:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmphasizedPrice(value);
      break;
    case 49:
      var value = /** @type {!proto.type.SubscriptionType} */ (reader.readEnum());
      msg.setSubscriptionType(value);
      break;
    case 50:
      var value = new proto.type.EcProduct.MedicineDescription;
      reader.readMessage(value,proto.type.EcProduct.MedicineDescription.deserializeBinaryFromReader);
      msg.setMedicineDescription(value);
      break;
    case 51:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDeliveryUnattended(value);
      break;
    case 52:
      var value = new type_sales_badge_pb.SalesBadge;
      reader.readMessage(value,type_sales_badge_pb.SalesBadge.deserializeBinaryFromReader);
      msg.setSalesBadge(value);
      break;
    case 53:
      var value = /** @type {!proto.type.BundleType} */ (reader.readEnum());
      msg.setBundleType(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setBundleLabel(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setBundleRuleId(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBundleRuleMinimumThreshold(value);
      break;
    case 57:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBundleRuleMinimumPrice(value);
      break;
    case 58:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setBundleRulePriceType(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setBundleName(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setBundleRuleSnapshotId(value);
      break;
    case 61:
      var value = new proto.type.EcProduct.ReservationPeriod;
      reader.readMessage(value,proto.type.EcProduct.ReservationPeriod.deserializeBinaryFromReader);
      msg.addReservationPeriods(value);
      break;
    case 62:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReservationProduct(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproximateTaxIncludedPrice(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproximateTaxExcludedPrice(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductNameKana(value);
      break;
    case 66:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecurringOrderProduct(value);
      break;
    case 67:
      var value = new type_interleaving_pb.Interleaving;
      reader.readMessage(value,type_interleaving_pb.Interleaving.deserializeBinaryFromReader);
      msg.setInterleaving(value);
      break;
    case 68:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitPricePer100g(value);
      break;
    case 69:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeightLabel(value);
      break;
    case 70:
      var value = new proto.type.VariableWeightProductSelling;
      reader.readMessage(value,proto.type.VariableWeightProductSelling.deserializeBinaryFromReader);
      msg.setVariableWeightProductSelling(value);
      break;
    case 71:
      var value = new proto.type.FixedWeightProductSelling;
      reader.readMessage(value,proto.type.FixedWeightProductSelling.deserializeBinaryFromReader);
      msg.setFixedWeightProductSelling(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeprecatedInTaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getNoTaxPrice();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCatalogId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInTaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getIsUnavailable();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getTaxRate();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDeprecatedTaxType();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getIsLiked();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getDeliveryLimitDateFrom();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryLimitDateTo();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getUnitPriceText();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getOtherImageUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getIsFavorite();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getQuantityLimit();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getIsMedical();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getDrugRiskClassification();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getBrandName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getIsCounselingRequired();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getPointInfo();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getDeliveryDateSale();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.type.EcProduct.DeliveryDateSale.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = message.getGroupKey();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getHasGroupInfo();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getColorName();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getSizeName();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getBrandNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      33,
      f
    );
  }
  f = message.getDisplayBrandName();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getDeliveryLimitTimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      type_delivery_limit_time_pb.DeliveryLimitTime.serializeBinaryToWriter
    );
  }
  f = message.getIsSelfMedication();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getSalesAgeRestrictionType();
  if (f !== 0.0) {
    writer.writeEnum(
      37,
      f
    );
  }
  f = message.getHasNotice();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getIsPcrKit();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getTaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      41,
      f
    );
  }
  f = message.getIsReducedTaxRate();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getIsExcludedEarnedPoint();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      44,
      f
    );
  }
  f = message.getPriceType();
  if (f !== 0.0) {
    writer.writeEnum(
      45,
      f
    );
  }
  f = message.getIsInSaleCategory();
  if (f) {
    writer.writeBool(
      46,
      f
    );
  }
  f = message.getIsAlcohol();
  if (f) {
    writer.writeBool(
      47,
      f
    );
  }
  f = message.getIsEmphasizedPrice();
  if (f) {
    writer.writeBool(
      48,
      f
    );
  }
  f = message.getSubscriptionType();
  if (f !== 0.0) {
    writer.writeEnum(
      49,
      f
    );
  }
  f = message.getMedicineDescription();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.type.EcProduct.MedicineDescription.serializeBinaryToWriter
    );
  }
  f = message.getCanDeliveryUnattended();
  if (f) {
    writer.writeBool(
      51,
      f
    );
  }
  f = message.getSalesBadge();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      type_sales_badge_pb.SalesBadge.serializeBinaryToWriter
    );
  }
  f = message.getBundleType();
  if (f !== 0.0) {
    writer.writeEnum(
      53,
      f
    );
  }
  f = message.getBundleLabel();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getBundleRuleId();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getBundleRuleMinimumThreshold();
  if (f !== 0) {
    writer.writeInt64(
      56,
      f
    );
  }
  f = message.getBundleRuleMinimumPrice();
  if (f !== 0) {
    writer.writeInt64(
      57,
      f
    );
  }
  f = message.getBundleRulePriceType();
  if (f !== 0.0) {
    writer.writeEnum(
      58,
      f
    );
  }
  f = message.getBundleName();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getBundleRuleSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getReservationPeriodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      61,
      f,
      proto.type.EcProduct.ReservationPeriod.serializeBinaryToWriter
    );
  }
  f = message.getIsReservationProduct();
  if (f) {
    writer.writeBool(
      62,
      f
    );
  }
  f = message.getApproximateTaxIncludedPrice();
  if (f.length > 0) {
    writer.writeString(
      63,
      f
    );
  }
  f = message.getApproximateTaxExcludedPrice();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getProductNameKana();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getIsRecurringOrderProduct();
  if (f) {
    writer.writeBool(
      66,
      f
    );
  }
  f = message.getInterleaving();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      type_interleaving_pb.Interleaving.serializeBinaryToWriter
    );
  }
  f = message.getUnitPricePer100g();
  if (f !== 0) {
    writer.writeInt64(
      68,
      f
    );
  }
  f = message.getWeightLabel();
  if (f.length > 0) {
    writer.writeString(
      69,
      f
    );
  }
  f = message.getVariableWeightProductSelling();
  if (f != null) {
    writer.writeMessage(
      70,
      f,
      proto.type.VariableWeightProductSelling.serializeBinaryToWriter
    );
  }
  f = message.getFixedWeightProductSelling();
  if (f != null) {
    writer.writeMessage(
      71,
      f,
      proto.type.FixedWeightProductSelling.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcProduct.DeliveryDateSale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcProduct.DeliveryDateSale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcProduct.DeliveryDateSale.toObject = function(includeInstance, msg) {
  var f, obj = {
    limitDateFrom: (f = msg.getLimitDateFrom()) && type_date_pb.Date.toObject(includeInstance, f),
    limitDateTo: (f = msg.getLimitDateTo()) && type_date_pb.Date.toObject(includeInstance, f),
    inTaxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    noTaxPrice: jspb.Message.getFieldWithDefault(msg, 4, 0),
    approximateTaxIncludedPrice: jspb.Message.getFieldWithDefault(msg, 5, ""),
    approximateTaxExcludedPrice: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeliveryDateSale as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcProduct.DeliveryDateSale.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.limitDateFrom;

  /** @type {?|undefined} */
  this.limitDateTo;

  /** @type {?|undefined} */
  this.inTaxPrice;

  /** @type {?|undefined} */
  this.noTaxPrice;

  /** @type {?|undefined} */
  this.approximateTaxIncludedPrice;

  /** @type {?|undefined} */
  this.approximateTaxExcludedPrice;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcProduct.DeliveryDateSale.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcProduct.DeliveryDateSale}
 */
proto.type.EcProduct.DeliveryDateSale.fromObject = function(obj) {
  var msg = new proto.type.EcProduct.DeliveryDateSale();
  obj.limitDateFrom && jspb.Message.setWrapperField(
      msg, 1, type_date_pb.Date.fromObject(obj.limitDateFrom));
  obj.limitDateTo && jspb.Message.setWrapperField(
      msg, 2, type_date_pb.Date.fromObject(obj.limitDateTo));
  obj.inTaxPrice != null && jspb.Message.setField(msg, 3, obj.inTaxPrice);
  obj.noTaxPrice != null && jspb.Message.setField(msg, 4, obj.noTaxPrice);
  obj.approximateTaxIncludedPrice != null && jspb.Message.setField(msg, 5, obj.approximateTaxIncludedPrice);
  obj.approximateTaxExcludedPrice != null && jspb.Message.setField(msg, 6, obj.approximateTaxExcludedPrice);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcProduct.DeliveryDateSale}
 */
proto.type.EcProduct.DeliveryDateSale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcProduct.DeliveryDateSale;
  return proto.type.EcProduct.DeliveryDateSale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcProduct.DeliveryDateSale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcProduct.DeliveryDateSale}
 */
proto.type.EcProduct.DeliveryDateSale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new type_date_pb.Date;
      reader.readMessage(value,type_date_pb.Date.deserializeBinaryFromReader);
      msg.setLimitDateFrom(value);
      break;
    case 2:
      var value = new type_date_pb.Date;
      reader.readMessage(value,type_date_pb.Date.deserializeBinaryFromReader);
      msg.setLimitDateTo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoTaxPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproximateTaxIncludedPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproximateTaxExcludedPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcProduct.DeliveryDateSale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcProduct.DeliveryDateSale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcProduct.DeliveryDateSale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitDateFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getLimitDateTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getInTaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getNoTaxPrice();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getApproximateTaxIncludedPrice();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getApproximateTaxExcludedPrice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional Date limit_date_from = 1;
 * @return {?proto.type.Date}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.getLimitDateFrom = function() {
  return /** @type{?proto.type.Date} */ (
    jspb.Message.getWrapperField(this, type_date_pb.Date, 1));
};


/**
 * @param {?proto.type.Date|undefined} value
 * @return {!proto.type.EcProduct.DeliveryDateSale} returns this
*/
proto.type.EcProduct.DeliveryDateSale.prototype.setLimitDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct.DeliveryDateSale} returns this
 */
proto.type.EcProduct.DeliveryDateSale.prototype.clearLimitDateFrom = function() {
  return this.setLimitDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.hasLimitDateFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Date limit_date_to = 2;
 * @return {?proto.type.Date}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.getLimitDateTo = function() {
  return /** @type{?proto.type.Date} */ (
    jspb.Message.getWrapperField(this, type_date_pb.Date, 2));
};


/**
 * @param {?proto.type.Date|undefined} value
 * @return {!proto.type.EcProduct.DeliveryDateSale} returns this
*/
proto.type.EcProduct.DeliveryDateSale.prototype.setLimitDateTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct.DeliveryDateSale} returns this
 */
proto.type.EcProduct.DeliveryDateSale.prototype.clearLimitDateTo = function() {
  return this.setLimitDateTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.hasLimitDateTo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double in_tax_price = 3;
 * @return {number}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.getInTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct.DeliveryDateSale} returns this
 */
proto.type.EcProduct.DeliveryDateSale.prototype.setInTaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 no_tax_price = 4;
 * @return {number}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.getNoTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct.DeliveryDateSale} returns this
 */
proto.type.EcProduct.DeliveryDateSale.prototype.setNoTaxPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string approximate_tax_included_price = 5;
 * @return {string}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.getApproximateTaxIncludedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct.DeliveryDateSale} returns this
 */
proto.type.EcProduct.DeliveryDateSale.prototype.setApproximateTaxIncludedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string approximate_tax_excluded_price = 6;
 * @return {string}
 */
proto.type.EcProduct.DeliveryDateSale.prototype.getApproximateTaxExcludedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct.DeliveryDateSale} returns this
 */
proto.type.EcProduct.DeliveryDateSale.prototype.setApproximateTaxExcludedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcProduct.MedicineDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcProduct.MedicineDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcProduct.MedicineDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcProduct.MedicineDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    medicineBrandCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    whenNotToUseProduct: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consultation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    indication: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dosageAndAdministration: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hasSuspectedAbusedMedicine: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    therapeuticCategory: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ingredientContent: jspb.Message.getFieldWithDefault(msg, 8, ""),
    packageInsertUrl: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of MedicineDescription as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcProduct.MedicineDescription.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.medicineBrandCode;

  /** @type {?|undefined} */
  this.whenNotToUseProduct;

  /** @type {?|undefined} */
  this.consultation;

  /** @type {?|undefined} */
  this.indication;

  /** @type {?|undefined} */
  this.dosageAndAdministration;

  /** @type {?|undefined} */
  this.hasSuspectedAbusedMedicine;

  /** @type {?|undefined} */
  this.therapeuticCategory;

  /** @type {?|undefined} */
  this.ingredientContent;

  /** @type {?|undefined} */
  this.packageInsertUrl;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcProduct.MedicineDescription.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcProduct.MedicineDescription}
 */
proto.type.EcProduct.MedicineDescription.fromObject = function(obj) {
  var msg = new proto.type.EcProduct.MedicineDescription();
  obj.medicineBrandCode != null && jspb.Message.setField(msg, 1, obj.medicineBrandCode);
  obj.whenNotToUseProduct != null && jspb.Message.setField(msg, 2, obj.whenNotToUseProduct);
  obj.consultation != null && jspb.Message.setField(msg, 3, obj.consultation);
  obj.indication != null && jspb.Message.setField(msg, 4, obj.indication);
  obj.dosageAndAdministration != null && jspb.Message.setField(msg, 5, obj.dosageAndAdministration);
  obj.hasSuspectedAbusedMedicine != null && jspb.Message.setField(msg, 6, obj.hasSuspectedAbusedMedicine);
  obj.therapeuticCategory != null && jspb.Message.setField(msg, 7, obj.therapeuticCategory);
  obj.ingredientContent != null && jspb.Message.setField(msg, 8, obj.ingredientContent);
  obj.packageInsertUrl != null && jspb.Message.setField(msg, 9, obj.packageInsertUrl);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcProduct.MedicineDescription}
 */
proto.type.EcProduct.MedicineDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcProduct.MedicineDescription;
  return proto.type.EcProduct.MedicineDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcProduct.MedicineDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcProduct.MedicineDescription}
 */
proto.type.EcProduct.MedicineDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMedicineBrandCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhenNotToUseProduct(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsultation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndication(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDosageAndAdministration(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSuspectedAbusedMedicine(value);
      break;
    case 7:
      var value = /** @type {!proto.type.TherapeuticCategory} */ (reader.readEnum());
      msg.setTherapeuticCategory(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIngredientContent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageInsertUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcProduct.MedicineDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcProduct.MedicineDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcProduct.MedicineDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcProduct.MedicineDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedicineBrandCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWhenNotToUseProduct();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsultation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIndication();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDosageAndAdministration();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHasSuspectedAbusedMedicine();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTherapeuticCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIngredientContent();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPackageInsertUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string medicine_brand_code = 1;
 * @return {string}
 */
proto.type.EcProduct.MedicineDescription.prototype.getMedicineBrandCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct.MedicineDescription} returns this
 */
proto.type.EcProduct.MedicineDescription.prototype.setMedicineBrandCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string when_not_to_use_product = 2;
 * @return {string}
 */
proto.type.EcProduct.MedicineDescription.prototype.getWhenNotToUseProduct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct.MedicineDescription} returns this
 */
proto.type.EcProduct.MedicineDescription.prototype.setWhenNotToUseProduct = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consultation = 3;
 * @return {string}
 */
proto.type.EcProduct.MedicineDescription.prototype.getConsultation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct.MedicineDescription} returns this
 */
proto.type.EcProduct.MedicineDescription.prototype.setConsultation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string indication = 4;
 * @return {string}
 */
proto.type.EcProduct.MedicineDescription.prototype.getIndication = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct.MedicineDescription} returns this
 */
proto.type.EcProduct.MedicineDescription.prototype.setIndication = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dosage_and_administration = 5;
 * @return {string}
 */
proto.type.EcProduct.MedicineDescription.prototype.getDosageAndAdministration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct.MedicineDescription} returns this
 */
proto.type.EcProduct.MedicineDescription.prototype.setDosageAndAdministration = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool has_suspected_abused_medicine = 6;
 * @return {boolean}
 */
proto.type.EcProduct.MedicineDescription.prototype.getHasSuspectedAbusedMedicine = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct.MedicineDescription} returns this
 */
proto.type.EcProduct.MedicineDescription.prototype.setHasSuspectedAbusedMedicine = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional TherapeuticCategory therapeutic_category = 7;
 * @return {!proto.type.TherapeuticCategory}
 */
proto.type.EcProduct.MedicineDescription.prototype.getTherapeuticCategory = function() {
  return /** @type {!proto.type.TherapeuticCategory} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.type.TherapeuticCategory} value
 * @return {!proto.type.EcProduct.MedicineDescription} returns this
 */
proto.type.EcProduct.MedicineDescription.prototype.setTherapeuticCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string ingredient_content = 8;
 * @return {string}
 */
proto.type.EcProduct.MedicineDescription.prototype.getIngredientContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct.MedicineDescription} returns this
 */
proto.type.EcProduct.MedicineDescription.prototype.setIngredientContent = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string package_insert_url = 9;
 * @return {string}
 */
proto.type.EcProduct.MedicineDescription.prototype.getPackageInsertUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct.MedicineDescription} returns this
 */
proto.type.EcProduct.MedicineDescription.prototype.setPackageInsertUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcProduct.ReservationPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcProduct.ReservationPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcProduct.ReservationPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcProduct.ReservationPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderStartAt: (f = msg.getOrderStartAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    orderEndAt: (f = msg.getOrderEndAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deliveryStartAt: (f = msg.getDeliveryStartAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deliveryEndAt: (f = msg.getDeliveryEndAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of ReservationPeriod as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcProduct.ReservationPeriod.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.orderStartAt;

  /** @type {?|undefined} */
  this.orderEndAt;

  /** @type {?|undefined} */
  this.deliveryStartAt;

  /** @type {?|undefined} */
  this.deliveryEndAt;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcProduct.ReservationPeriod.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcProduct.ReservationPeriod}
 */
proto.type.EcProduct.ReservationPeriod.fromObject = function(obj) {
  var msg = new proto.type.EcProduct.ReservationPeriod();
  obj.orderStartAt && jspb.Message.setWrapperField(
      msg, 1, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.orderStartAt));
  obj.orderEndAt && jspb.Message.setWrapperField(
      msg, 2, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.orderEndAt));
  obj.deliveryStartAt && jspb.Message.setWrapperField(
      msg, 3, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveryStartAt));
  obj.deliveryEndAt && jspb.Message.setWrapperField(
      msg, 4, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveryEndAt));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcProduct.ReservationPeriod}
 */
proto.type.EcProduct.ReservationPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcProduct.ReservationPeriod;
  return proto.type.EcProduct.ReservationPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcProduct.ReservationPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcProduct.ReservationPeriod}
 */
proto.type.EcProduct.ReservationPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOrderStartAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOrderEndAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryStartAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryEndAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcProduct.ReservationPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcProduct.ReservationPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcProduct.ReservationPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcProduct.ReservationPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderStartAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrderEndAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryStartAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryEndAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp order_start_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.EcProduct.ReservationPeriod.prototype.getOrderStartAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.EcProduct.ReservationPeriod} returns this
*/
proto.type.EcProduct.ReservationPeriod.prototype.setOrderStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct.ReservationPeriod} returns this
 */
proto.type.EcProduct.ReservationPeriod.prototype.clearOrderStartAt = function() {
  return this.setOrderStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.ReservationPeriod.prototype.hasOrderStartAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp order_end_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.EcProduct.ReservationPeriod.prototype.getOrderEndAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.EcProduct.ReservationPeriod} returns this
*/
proto.type.EcProduct.ReservationPeriod.prototype.setOrderEndAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct.ReservationPeriod} returns this
 */
proto.type.EcProduct.ReservationPeriod.prototype.clearOrderEndAt = function() {
  return this.setOrderEndAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.ReservationPeriod.prototype.hasOrderEndAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp delivery_start_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.EcProduct.ReservationPeriod.prototype.getDeliveryStartAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.EcProduct.ReservationPeriod} returns this
*/
proto.type.EcProduct.ReservationPeriod.prototype.setDeliveryStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct.ReservationPeriod} returns this
 */
proto.type.EcProduct.ReservationPeriod.prototype.clearDeliveryStartAt = function() {
  return this.setDeliveryStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.ReservationPeriod.prototype.hasDeliveryStartAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp delivery_end_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.EcProduct.ReservationPeriod.prototype.getDeliveryEndAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.EcProduct.ReservationPeriod} returns this
*/
proto.type.EcProduct.ReservationPeriod.prototype.setDeliveryEndAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct.ReservationPeriod} returns this
 */
proto.type.EcProduct.ReservationPeriod.prototype.clearDeliveryEndAt = function() {
  return this.setDeliveryEndAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.ReservationPeriod.prototype.hasDeliveryEndAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EcSite.SiteType site_type = 1;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.type.EcProduct.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.type.EcProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.type.EcProduct.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.type.EcProduct.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.type.EcProduct.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double deprecated_in_tax_price = 6;
 * @return {number}
 */
proto.type.EcProduct.prototype.getDeprecatedInTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setDeprecatedInTaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 no_tax_price = 7;
 * @return {number}
 */
proto.type.EcProduct.prototype.getNoTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setNoTaxPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string catalog_id = 8;
 * @return {string}
 */
proto.type.EcProduct.prototype.getCatalogId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setCatalogId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double in_tax_price = 9;
 * @return {number}
 */
proto.type.EcProduct.prototype.getInTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setInTaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional bool is_unavailable = 10;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsUnavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsUnavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 tax_rate = 11;
 * @return {number}
 */
proto.type.EcProduct.prototype.getTaxRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setTaxRate = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 deprecated_tax_type = 12;
 * @return {number}
 */
proto.type.EcProduct.prototype.getDeprecatedTaxType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setDeprecatedTaxType = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool is_liked = 13;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsLiked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsLiked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional Date delivery_limit_date_from = 14;
 * @return {?proto.type.Date}
 */
proto.type.EcProduct.prototype.getDeliveryLimitDateFrom = function() {
  return /** @type{?proto.type.Date} */ (
    jspb.Message.getWrapperField(this, type_date_pb.Date, 14));
};


/**
 * @param {?proto.type.Date|undefined} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setDeliveryLimitDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearDeliveryLimitDateFrom = function() {
  return this.setDeliveryLimitDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.prototype.hasDeliveryLimitDateFrom = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Date delivery_limit_date_to = 15;
 * @return {?proto.type.Date}
 */
proto.type.EcProduct.prototype.getDeliveryLimitDateTo = function() {
  return /** @type{?proto.type.Date} */ (
    jspb.Message.getWrapperField(this, type_date_pb.Date, 15));
};


/**
 * @param {?proto.type.Date|undefined} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setDeliveryLimitDateTo = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearDeliveryLimitDateTo = function() {
  return this.setDeliveryLimitDateTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.prototype.hasDeliveryLimitDateTo = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string detail = 16;
 * @return {string}
 */
proto.type.EcProduct.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string unit_price_text = 17;
 * @return {string}
 */
proto.type.EcProduct.prototype.getUnitPriceText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setUnitPriceText = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string from = 18;
 * @return {string}
 */
proto.type.EcProduct.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated string other_image_urls = 19;
 * @return {!Array<string>}
 */
proto.type.EcProduct.prototype.getOtherImageUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setOtherImageUrlsList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.addOtherImageUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearOtherImageUrlsList = function() {
  return this.setOtherImageUrlsList([]);
};


/**
 * optional bool is_favorite = 20;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional int64 quantity_limit = 21;
 * @return {number}
 */
proto.type.EcProduct.prototype.getQuantityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setQuantityLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional bool is_medical = 22;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsMedical = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsMedical = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional DrugRiskClassification drug_risk_classification = 23;
 * @return {!proto.type.DrugRiskClassification}
 */
proto.type.EcProduct.prototype.getDrugRiskClassification = function() {
  return /** @type {!proto.type.DrugRiskClassification} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.type.DrugRiskClassification} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setDrugRiskClassification = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional string brand_name = 24;
 * @return {string}
 */
proto.type.EcProduct.prototype.getBrandName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBrandName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional bool is_counseling_required = 25;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsCounselingRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsCounselingRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional string point_info = 26;
 * @return {string}
 */
proto.type.EcProduct.prototype.getPointInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setPointInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional DeliveryDateSale delivery_date_sale = 27;
 * @return {?proto.type.EcProduct.DeliveryDateSale}
 */
proto.type.EcProduct.prototype.getDeliveryDateSale = function() {
  return /** @type{?proto.type.EcProduct.DeliveryDateSale} */ (
    jspb.Message.getWrapperField(this, proto.type.EcProduct.DeliveryDateSale, 27));
};


/**
 * @param {?proto.type.EcProduct.DeliveryDateSale|undefined} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setDeliveryDateSale = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.type.EcProduct.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearDeliveryDateSale = function() {
  return this.setDeliveryDateSale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.prototype.hasDeliveryDateSale = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional int64 amount = 28;
 * @return {number}
 */
proto.type.EcProduct.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional string group_key = 29;
 * @return {string}
 */
proto.type.EcProduct.prototype.getGroupKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setGroupKey = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional bool has_group_info = 30;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getHasGroupInfo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setHasGroupInfo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional string color_name = 31;
 * @return {string}
 */
proto.type.EcProduct.prototype.getColorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setColorName = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string size_name = 32;
 * @return {string}
 */
proto.type.EcProduct.prototype.getSizeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setSizeName = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * repeated string brand_names = 33;
 * @return {!Array<string>}
 */
proto.type.EcProduct.prototype.getBrandNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 33));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBrandNamesList = function(value) {
  return jspb.Message.setField(this, 33, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.addBrandNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 33, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearBrandNamesList = function() {
  return this.setBrandNamesList([]);
};


/**
 * optional string display_brand_name = 34;
 * @return {string}
 */
proto.type.EcProduct.prototype.getDisplayBrandName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setDisplayBrandName = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * repeated DeliveryLimitTime delivery_limit_times = 35;
 * @return {!Array<!proto.type.DeliveryLimitTime>}
 */
proto.type.EcProduct.prototype.getDeliveryLimitTimesList = function() {
  return /** @type{!Array<!proto.type.DeliveryLimitTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_delivery_limit_time_pb.DeliveryLimitTime, 35));
};


/**
 * @param {!Array<!proto.type.DeliveryLimitTime>} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setDeliveryLimitTimesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.type.DeliveryLimitTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.DeliveryLimitTime}
 */
proto.type.EcProduct.prototype.addDeliveryLimitTimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.type.DeliveryLimitTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearDeliveryLimitTimesList = function() {
  return this.setDeliveryLimitTimesList([]);
};


/**
 * optional bool is_self_medication = 36;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsSelfMedication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsSelfMedication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional SalesAgeRestrictionType sales_age_restriction_type = 37;
 * @return {!proto.type.SalesAgeRestrictionType}
 */
proto.type.EcProduct.prototype.getSalesAgeRestrictionType = function() {
  return /** @type {!proto.type.SalesAgeRestrictionType} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {!proto.type.SalesAgeRestrictionType} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setSalesAgeRestrictionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 37, value);
};


/**
 * optional bool has_notice = 39;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getHasNotice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setHasNotice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional bool is_pcr_kit = 40;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsPcrKit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsPcrKit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional TaxType tax_type = 41;
 * @return {!proto.type.TaxType}
 */
proto.type.EcProduct.prototype.getTaxType = function() {
  return /** @type {!proto.type.TaxType} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {!proto.type.TaxType} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setTaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 41, value);
};


/**
 * optional bool is_reduced_tax_rate = 42;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsReducedTaxRate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsReducedTaxRate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool is_excluded_earned_point = 43;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsExcludedEarnedPoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsExcludedEarnedPoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional int64 price = 44;
 * @return {number}
 */
proto.type.EcProduct.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};


/**
 * optional PriceType price_type = 45;
 * @return {!proto.type.PriceType}
 */
proto.type.EcProduct.prototype.getPriceType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setPriceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 45, value);
};


/**
 * optional bool is_in_sale_category = 46;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsInSaleCategory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 46, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsInSaleCategory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 46, value);
};


/**
 * optional bool is_alcohol = 47;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsAlcohol = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 47, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsAlcohol = function(value) {
  return jspb.Message.setProto3BooleanField(this, 47, value);
};


/**
 * optional bool is_emphasized_price = 48;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsEmphasizedPrice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 48, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsEmphasizedPrice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 48, value);
};


/**
 * optional SubscriptionType subscription_type = 49;
 * @return {!proto.type.SubscriptionType}
 */
proto.type.EcProduct.prototype.getSubscriptionType = function() {
  return /** @type {!proto.type.SubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 49, 0));
};


/**
 * @param {!proto.type.SubscriptionType} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setSubscriptionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 49, value);
};


/**
 * optional MedicineDescription medicine_description = 50;
 * @return {?proto.type.EcProduct.MedicineDescription}
 */
proto.type.EcProduct.prototype.getMedicineDescription = function() {
  return /** @type{?proto.type.EcProduct.MedicineDescription} */ (
    jspb.Message.getWrapperField(this, proto.type.EcProduct.MedicineDescription, 50));
};


/**
 * @param {?proto.type.EcProduct.MedicineDescription|undefined} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setMedicineDescription = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearMedicineDescription = function() {
  return this.setMedicineDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.prototype.hasMedicineDescription = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional bool can_delivery_unattended = 51;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getCanDeliveryUnattended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 51, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setCanDeliveryUnattended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 51, value);
};


/**
 * optional SalesBadge sales_badge = 52;
 * @return {?proto.type.SalesBadge}
 */
proto.type.EcProduct.prototype.getSalesBadge = function() {
  return /** @type{?proto.type.SalesBadge} */ (
    jspb.Message.getWrapperField(this, type_sales_badge_pb.SalesBadge, 52));
};


/**
 * @param {?proto.type.SalesBadge|undefined} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setSalesBadge = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearSalesBadge = function() {
  return this.setSalesBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.prototype.hasSalesBadge = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional BundleType bundle_type = 53;
 * @return {!proto.type.BundleType}
 */
proto.type.EcProduct.prototype.getBundleType = function() {
  return /** @type {!proto.type.BundleType} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {!proto.type.BundleType} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBundleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 53, value);
};


/**
 * optional string bundle_label = 54;
 * @return {string}
 */
proto.type.EcProduct.prototype.getBundleLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBundleLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string bundle_rule_id = 55;
 * @return {string}
 */
proto.type.EcProduct.prototype.getBundleRuleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBundleRuleId = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional int64 bundle_rule_minimum_threshold = 56;
 * @return {number}
 */
proto.type.EcProduct.prototype.getBundleRuleMinimumThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 56, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBundleRuleMinimumThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 56, value);
};


/**
 * optional int64 bundle_rule_minimum_price = 57;
 * @return {number}
 */
proto.type.EcProduct.prototype.getBundleRuleMinimumPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBundleRuleMinimumPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 57, value);
};


/**
 * optional PriceType bundle_rule_price_type = 58;
 * @return {!proto.type.PriceType}
 */
proto.type.EcProduct.prototype.getBundleRulePriceType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 58, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBundleRulePriceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 58, value);
};


/**
 * optional string bundle_name = 59;
 * @return {string}
 */
proto.type.EcProduct.prototype.getBundleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBundleName = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * optional string bundle_rule_snapshot_id = 60;
 * @return {string}
 */
proto.type.EcProduct.prototype.getBundleRuleSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setBundleRuleSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * repeated ReservationPeriod reservation_periods = 61;
 * @return {!Array<!proto.type.EcProduct.ReservationPeriod>}
 */
proto.type.EcProduct.prototype.getReservationPeriodsList = function() {
  return /** @type{!Array<!proto.type.EcProduct.ReservationPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.EcProduct.ReservationPeriod, 61));
};


/**
 * @param {!Array<!proto.type.EcProduct.ReservationPeriod>} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setReservationPeriodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 61, value);
};


/**
 * @param {!proto.type.EcProduct.ReservationPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.EcProduct.ReservationPeriod}
 */
proto.type.EcProduct.prototype.addReservationPeriods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 61, opt_value, proto.type.EcProduct.ReservationPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearReservationPeriodsList = function() {
  return this.setReservationPeriodsList([]);
};


/**
 * optional bool is_reservation_product = 62;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsReservationProduct = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 62, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsReservationProduct = function(value) {
  return jspb.Message.setProto3BooleanField(this, 62, value);
};


/**
 * optional string approximate_tax_included_price = 63;
 * @return {string}
 */
proto.type.EcProduct.prototype.getApproximateTaxIncludedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setApproximateTaxIncludedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 63, value);
};


/**
 * optional string approximate_tax_excluded_price = 64;
 * @return {string}
 */
proto.type.EcProduct.prototype.getApproximateTaxExcludedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setApproximateTaxExcludedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional string product_name_kana = 65;
 * @return {string}
 */
proto.type.EcProduct.prototype.getProductNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setProductNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};


/**
 * optional bool is_recurring_order_product = 66;
 * @return {boolean}
 */
proto.type.EcProduct.prototype.getIsRecurringOrderProduct = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 66, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setIsRecurringOrderProduct = function(value) {
  return jspb.Message.setProto3BooleanField(this, 66, value);
};


/**
 * optional Interleaving interleaving = 67;
 * @return {?proto.type.Interleaving}
 */
proto.type.EcProduct.prototype.getInterleaving = function() {
  return /** @type{?proto.type.Interleaving} */ (
    jspb.Message.getWrapperField(this, type_interleaving_pb.Interleaving, 67));
};


/**
 * @param {?proto.type.Interleaving|undefined} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setInterleaving = function(value) {
  return jspb.Message.setWrapperField(this, 67, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearInterleaving = function() {
  return this.setInterleaving(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.prototype.hasInterleaving = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional int64 unit_price_per_100g = 68;
 * @return {number}
 */
proto.type.EcProduct.prototype.getUnitPricePer100g = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setUnitPricePer100g = function(value) {
  return jspb.Message.setProto3IntField(this, 68, value);
};


/**
 * optional string weight_label = 69;
 * @return {string}
 */
proto.type.EcProduct.prototype.getWeightLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 69, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.setWeightLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 69, value);
};


/**
 * optional VariableWeightProductSelling variable_weight_product_selling = 70;
 * @return {?proto.type.VariableWeightProductSelling}
 */
proto.type.EcProduct.prototype.getVariableWeightProductSelling = function() {
  return /** @type{?proto.type.VariableWeightProductSelling} */ (
    jspb.Message.getWrapperField(this, proto.type.VariableWeightProductSelling, 70));
};


/**
 * @param {?proto.type.VariableWeightProductSelling|undefined} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setVariableWeightProductSelling = function(value) {
  return jspb.Message.setOneofWrapperField(this, 70, proto.type.EcProduct.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearVariableWeightProductSelling = function() {
  return this.setVariableWeightProductSelling(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.prototype.hasVariableWeightProductSelling = function() {
  return jspb.Message.getField(this, 70) != null;
};


/**
 * optional FixedWeightProductSelling fixed_weight_product_selling = 71;
 * @return {?proto.type.FixedWeightProductSelling}
 */
proto.type.EcProduct.prototype.getFixedWeightProductSelling = function() {
  return /** @type{?proto.type.FixedWeightProductSelling} */ (
    jspb.Message.getWrapperField(this, proto.type.FixedWeightProductSelling, 71));
};


/**
 * @param {?proto.type.FixedWeightProductSelling|undefined} value
 * @return {!proto.type.EcProduct} returns this
*/
proto.type.EcProduct.prototype.setFixedWeightProductSelling = function(value) {
  return jspb.Message.setOneofWrapperField(this, 71, proto.type.EcProduct.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcProduct} returns this
 */
proto.type.EcProduct.prototype.clearFixedWeightProductSelling = function() {
  return this.setFixedWeightProductSelling(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcProduct.prototype.hasFixedWeightProductSelling = function() {
  return jspb.Message.getField(this, 71) != null;
};


/**
 * @enum {number}
 */
proto.type.EcProductCategory = {
  NOCATEGORY: 0,
  VEGETABLE: 1,
  MEAT: 2,
  SEAFOOD: 3,
  TOFU_KONJAK_NOODLE: 4,
  DAIRY_EGG: 5,
  FROZEN: 6,
  DRY_CANNED_BOTTLED: 7,
  BEVERAGE: 8,
  BREAD_CONFECTIONERY: 9,
  SEASONING: 10,
  KITCHEN_SUPPLY: 11,
  USER: 12,
  ALCOHOL: 101,
  DAILY_DISH: 102,
  DAILY_NECESSITIES: 103,
  COSMETICS: 104,
  MEDICINE: 105,
  MISCELLANEOUS_GOODS: 106,
  PET_GOODS: 107,
  CLOTHING: 108,
  BABY_GOODS: 109
};

/**
 * @enum {number}
 */
proto.type.DrugRiskClassification = {
  NONE: 0,
  FIRST: 1,
  SPECIFIED_SECOND: 2,
  SECOND: 3,
  THIRD: 4,
  QUASI: 5,
  REQUIRING_GUIDANCE: 6,
  ETHICAL_DRUG: 7,
  POISONOUS_AND_DELETERIOUS: 8,
  MEDICAL_DEVICE: 9,
  SPECIAL_MEDICAL_DEVICE: 10
};

/**
 * @enum {number}
 */
proto.type.TherapeuticCategory = {
  THERAPEUTIC_CATEGORY_UNSPECIFIED: 0,
  COLD_REMEDIES: 1,
  ANTIPYRETIC_ANALGESICS: 2,
  HYPNOTICS_AND_SEDATIVES: 3,
  ANTITUSSIVES_AND_EXPECTORANTS: 4,
  ORAL_REMEDIES_FOR_RHINITIS: 5,
  HERBAL_DRUGS: 6,
  GYNECOLOGICAL_MEDICINE: 7,
  ANTIVERTIGO_MEDICINES: 8,
  DERMAL_AGENTS: 9
};

/**
 * @enum {number}
 */
proto.type.SalesAgeRestrictionType = {
  FREE: 0,
  OVER_20: 1
};

goog.object.extend(exports, proto.type);
