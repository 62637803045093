/**
 * @fileoverview gRPC-Web generated client stub for stailer_external_admin
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as stailer_externaladminrpc_external_admin_user_service_pb from '../../stailer/externaladminrpc/external_admin_user_service_pb';


export class ExternalAdminUserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreate = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserResponse.deserializeBinary
  );

  create(
    request: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserResponse>;

  create(
    request: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserResponse>;

  create(
    request: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/Create',
        request,
        metadata || {},
        this.methodInfoCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/Create',
    request,
    metadata || {},
    this.methodInfoCreate);
  }

  methodInfoLogin = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserResponse.deserializeBinary
  );

  login(
    request: stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserResponse>;

  login(
    request: stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserResponse>;

  login(
    request: stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.LoginExternalAdminUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/Login',
        request,
        metadata || {},
        this.methodInfoLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/Login',
    request,
    metadata || {},
    this.methodInfoLogin);
  }

  methodInfoList = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.ListResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.ListRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.ListResponse.deserializeBinary
  );

  list(
    request: stailer_externaladminrpc_external_admin_user_service_pb.ListRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.ListResponse>;

  list(
    request: stailer_externaladminrpc_external_admin_user_service_pb.ListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.ListResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.ListResponse>;

  list(
    request: stailer_externaladminrpc_external_admin_user_service_pb.ListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.ListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/List',
        request,
        metadata || {},
        this.methodInfoList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/List',
    request,
    metadata || {},
    this.methodInfoList);
  }

  methodInfoGet = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserResponse.deserializeBinary
  );

  get(
    request: stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserResponse>;

  get(
    request: stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserResponse>;

  get(
    request: stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.GetExternalAdminUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/Get',
        request,
        metadata || {},
        this.methodInfoGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/Get',
    request,
    metadata || {},
    this.methodInfoGet);
  }

  methodInfoGetById = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserResponse.deserializeBinary
  );

  getById(
    request: stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserResponse>;

  getById(
    request: stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserResponse>;

  getById(
    request: stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.GetByIdExternalAdminUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/GetById',
        request,
        metadata || {},
        this.methodInfoGetById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/GetById',
    request,
    metadata || {},
    this.methodInfoGetById);
  }

  methodInfoUpdateUser = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserResponse.deserializeBinary
  );

  updateUser(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserResponse>;

  updateUser(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserResponse>;

  updateUser(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.UpdateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/UpdateUser',
        request,
        metadata || {},
        this.methodInfoUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/UpdateUser',
    request,
    metadata || {},
    this.methodInfoUpdateUser);
  }

  methodInfoUpdateSelf = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfResponse.deserializeBinary
  );

  updateSelf(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfResponse>;

  updateSelf(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfResponse>;

  updateSelf(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.UpdateSelfResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/UpdateSelf',
        request,
        metadata || {},
        this.methodInfoUpdateSelf,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/UpdateSelf',
    request,
    metadata || {},
    this.methodInfoUpdateSelf);
  }

  methodInfoUpdateSelectShop = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopResponse.deserializeBinary
  );

  updateSelectShop(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopResponse>;

  updateSelectShop(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopResponse>;

  updateSelectShop(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.UpdateExternalAdminUserSelectShopResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/UpdateSelectShop',
        request,
        metadata || {},
        this.methodInfoUpdateSelectShop,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/UpdateSelectShop',
    request,
    metadata || {},
    this.methodInfoUpdateSelectShop);
  }

  methodInfoDelete = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.DeleteResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.DeleteRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.DeleteResponse.deserializeBinary
  );

  delete(
    request: stailer_externaladminrpc_external_admin_user_service_pb.DeleteRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.DeleteResponse>;

  delete(
    request: stailer_externaladminrpc_external_admin_user_service_pb.DeleteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.DeleteResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.DeleteResponse>;

  delete(
    request: stailer_externaladminrpc_external_admin_user_service_pb.DeleteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.DeleteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/Delete',
        request,
        metadata || {},
        this.methodInfoDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/Delete',
    request,
    metadata || {},
    this.methodInfoDelete);
  }

  methodInfoInvite = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.InviteResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.InviteRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.InviteResponse.deserializeBinary
  );

  invite(
    request: stailer_externaladminrpc_external_admin_user_service_pb.InviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.InviteResponse>;

  invite(
    request: stailer_externaladminrpc_external_admin_user_service_pb.InviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.InviteResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.InviteResponse>;

  invite(
    request: stailer_externaladminrpc_external_admin_user_service_pb.InviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.InviteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/Invite',
        request,
        metadata || {},
        this.methodInfoInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/Invite',
    request,
    metadata || {},
    this.methodInfoInvite);
  }

  methodInfoCreateDeviceToken = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenResponse.deserializeBinary
  );

  createDeviceToken(
    request: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenResponse>;

  createDeviceToken(
    request: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenResponse>;

  createDeviceToken(
    request: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.CreateExternalAdminUserDeviceTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/CreateDeviceToken',
        request,
        metadata || {},
        this.methodInfoCreateDeviceToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/CreateDeviceToken',
    request,
    metadata || {},
    this.methodInfoCreateDeviceToken);
  }

  methodInfoUpdatePushNotificationSettings = new grpcWeb.AbstractClientBase.MethodInfo(
    stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsResponse,
    (request: stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsRequest) => {
      return request.serializeBinary();
    },
    stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsResponse.deserializeBinary
  );

  updatePushNotificationSettings(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsResponse>;

  updatePushNotificationSettings(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsResponse) => void): grpcWeb.ClientReadableStream<stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsResponse>;

  updatePushNotificationSettings(
    request: stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: stailer_externaladminrpc_external_admin_user_service_pb.UpdatePushNotificationSettingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/stailer_external_admin.ExternalAdminUserService/UpdatePushNotificationSettings',
        request,
        metadata || {},
        this.methodInfoUpdatePushNotificationSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/stailer_external_admin.ExternalAdminUserService/UpdatePushNotificationSettings',
    request,
    metadata || {},
    this.methodInfoUpdatePushNotificationSettings);
  }

}

