// source: stailer/externaladminrpc/transaction_record_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var type_price_pb = require('../../type/price_pb.js');
goog.object.extend(proto, type_price_pb);
goog.exportSymbol('proto.stailer_external_admin.GetTransactionRecordRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.GetTransactionRecordResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.ReceiptIssuance', null, global);
goog.exportSymbol('proto.stailer_external_admin.SearchTransactionRecordsRequest', null, global);
goog.exportSymbol('proto.stailer_external_admin.SearchTransactionRecordsRequest.Query', null, global);
goog.exportSymbol('proto.stailer_external_admin.SearchTransactionRecordsResponse', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.GroupChildLineItem', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.GroupLineItem', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.LineItem', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.LineItem.ItemCase', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.PaymentType', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.SingleLineItem', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.Subscription', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.Summary', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.TaxDetail', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.TaxDetail.Item', null, global);
goog.exportSymbol('proto.stailer_external_admin.TransactionRecord.TransactionType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.TransactionRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stailer_external_admin.TransactionRecord.repeatedFields_, null);
};
goog.inherits(proto.stailer_external_admin.TransactionRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.TransactionRecord.displayName = 'proto.stailer_external_admin.TransactionRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stailer_external_admin.TransactionRecord.TaxDetail.repeatedFields_, null);
};
goog.inherits(proto.stailer_external_admin.TransactionRecord.TaxDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.TransactionRecord.TaxDetail.displayName = 'proto.stailer_external_admin.TransactionRecord.TaxDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.TransactionRecord.TaxDetail.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.displayName = 'proto.stailer_external_admin.TransactionRecord.TaxDetail.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.TransactionRecord.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.TransactionRecord.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.TransactionRecord.Subscription.displayName = 'proto.stailer_external_admin.TransactionRecord.Subscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.TransactionRecord.Summary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.TransactionRecord.Summary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.TransactionRecord.Summary.displayName = 'proto.stailer_external_admin.TransactionRecord.Summary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.TransactionRecord.SingleLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.TransactionRecord.SingleLineItem.displayName = 'proto.stailer_external_admin.TransactionRecord.SingleLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stailer_external_admin.TransactionRecord.GroupLineItem.repeatedFields_, null);
};
goog.inherits(proto.stailer_external_admin.TransactionRecord.GroupLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.TransactionRecord.GroupLineItem.displayName = 'proto.stailer_external_admin.TransactionRecord.GroupLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.TransactionRecord.GroupChildLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.displayName = 'proto.stailer_external_admin.TransactionRecord.GroupChildLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.TransactionRecord.LineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.stailer_external_admin.TransactionRecord.LineItem.oneofGroups_);
};
goog.inherits(proto.stailer_external_admin.TransactionRecord.LineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.TransactionRecord.LineItem.displayName = 'proto.stailer_external_admin.TransactionRecord.LineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.ReceiptIssuance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.ReceiptIssuance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.ReceiptIssuance.displayName = 'proto.stailer_external_admin.ReceiptIssuance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.SearchTransactionRecordsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.SearchTransactionRecordsRequest.displayName = 'proto.stailer_external_admin.SearchTransactionRecordsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.SearchTransactionRecordsRequest.Query, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.displayName = 'proto.stailer_external_admin.SearchTransactionRecordsRequest.Query';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stailer_external_admin.SearchTransactionRecordsResponse.repeatedFields_, null);
};
goog.inherits(proto.stailer_external_admin.SearchTransactionRecordsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.SearchTransactionRecordsResponse.displayName = 'proto.stailer_external_admin.SearchTransactionRecordsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.GetTransactionRecordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.GetTransactionRecordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.GetTransactionRecordRequest.displayName = 'proto.stailer_external_admin.GetTransactionRecordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.GetTransactionRecordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stailer_external_admin.GetTransactionRecordResponse.repeatedFields_, null);
};
goog.inherits(proto.stailer_external_admin.GetTransactionRecordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.GetTransactionRecordResponse.displayName = 'proto.stailer_external_admin.GetTransactionRecordResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stailer_external_admin.TransactionRecord.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.TransactionRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.TransactionRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.TransactionRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    summary: (f = msg.getSummary()) && proto.stailer_external_admin.TransactionRecord.Summary.toObject(includeInstance, f),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.stailer_external_admin.TransactionRecord.LineItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of TransactionRecord as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.TransactionRecord.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.summary;

  /** @type {?|undefined} */
  this.lineItemsList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.TransactionRecord.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.TransactionRecord}
 */
proto.stailer_external_admin.TransactionRecord.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.TransactionRecord();
  obj.summary && jspb.Message.setWrapperField(
      msg, 1, proto.stailer_external_admin.TransactionRecord.Summary.fromObject(obj.summary));
  obj.lineItemsList && jspb.Message.setRepeatedWrapperField(
      msg, 2, obj.lineItemsList.map(
          proto.stailer_external_admin.TransactionRecord.LineItem.fromObject));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.TransactionRecord}
 */
proto.stailer_external_admin.TransactionRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.TransactionRecord;
  return proto.stailer_external_admin.TransactionRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.TransactionRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.TransactionRecord}
 */
proto.stailer_external_admin.TransactionRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stailer_external_admin.TransactionRecord.Summary;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.Summary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 2:
      var value = new proto.stailer_external_admin.TransactionRecord.LineItem;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.LineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.TransactionRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.TransactionRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.TransactionRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stailer_external_admin.TransactionRecord.Summary.serializeBinaryToWriter
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.stailer_external_admin.TransactionRecord.LineItem.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.stailer_external_admin.TransactionRecord.TransactionType = {
  UNKNOWN_TRANSACTION_TYPE: 0,
  ORDER: 1,
  SUBSCRIPTION: 2
};

/**
 * @enum {number}
 */
proto.stailer_external_admin.TransactionRecord.PaymentType = {
  UNSPECIFIED_PAYMENT_TYPE: 0,
  CREDIT_CARD: 1,
  LOCAL_PAYMENT: 2,
  CASH_ON_DELIVERY: 3,
  INVOICE: 4,
  BANK_ACCOUNT_TRANSFER: 5
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.TransactionRecord.TaxDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.TransactionRecord.TaxDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxDetailItemsList: jspb.Message.toObjectList(msg.getTaxDetailItemsList(),
    proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of TaxDetail as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.taxDetailItemsList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.TransactionRecord.TaxDetail.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.TransactionRecord.TaxDetail();
  obj.taxDetailItemsList && jspb.Message.setRepeatedWrapperField(
      msg, 1, obj.taxDetailItemsList.map(
          proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.fromObject));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.TransactionRecord.TaxDetail;
  return proto.stailer_external_admin.TransactionRecord.TaxDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.TransactionRecord.TaxDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stailer_external_admin.TransactionRecord.TaxDetail.Item;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.deserializeBinaryFromReader);
      msg.addTaxDetailItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.TransactionRecord.TaxDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.TransactionRecord.TaxDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxDetailItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxRatePercentage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taxableAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    taxAmount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Item as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.taxRatePercentage;

  /** @type {?|undefined} */
  this.taxableAmount;

  /** @type {?|undefined} */
  this.taxAmount;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.TransactionRecord.TaxDetail.Item();
  obj.taxRatePercentage != null && jspb.Message.setField(msg, 1, obj.taxRatePercentage);
  obj.taxableAmount != null && jspb.Message.setField(msg, 2, obj.taxableAmount);
  obj.taxAmount != null && jspb.Message.setField(msg, 3, obj.taxAmount);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.TransactionRecord.TaxDetail.Item;
  return proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxRatePercentage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxableAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxRatePercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTaxableAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 tax_rate_percentage = 1;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.prototype.getTaxRatePercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item} returns this
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.prototype.setTaxRatePercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 taxable_amount = 2;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.prototype.getTaxableAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item} returns this
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.prototype.setTaxableAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 tax_amount = 3;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item} returns this
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.Item.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Item tax_detail_items = 1;
 * @return {!Array<!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item>}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.prototype.getTaxDetailItemsList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stailer_external_admin.TransactionRecord.TaxDetail.Item, 1));
};


/**
 * @param {!Array<!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item>} value
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail} returns this
*/
proto.stailer_external_admin.TransactionRecord.TaxDetail.prototype.setTaxDetailItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail.Item}
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.prototype.addTaxDetailItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.stailer_external_admin.TransactionRecord.TaxDetail.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.TransactionRecord.TaxDetail} returns this
 */
proto.stailer_external_admin.TransactionRecord.TaxDetail.prototype.clearTaxDetailItemsList = function() {
  return this.setTaxDetailItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.TransactionRecord.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.TransactionRecord.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.TransactionRecord.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Subscription as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.TransactionRecord.Subscription.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.planId;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.TransactionRecord.Subscription.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.TransactionRecord.Subscription}
 */
proto.stailer_external_admin.TransactionRecord.Subscription.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.TransactionRecord.Subscription();
  obj.planId != null && jspb.Message.setField(msg, 1, obj.planId);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.TransactionRecord.Subscription}
 */
proto.stailer_external_admin.TransactionRecord.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.TransactionRecord.Subscription;
  return proto.stailer_external_admin.TransactionRecord.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.TransactionRecord.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.TransactionRecord.Subscription}
 */
proto.stailer_external_admin.TransactionRecord.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.TransactionRecord.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.TransactionRecord.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.TransactionRecord.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string plan_id = 1;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.Subscription.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Subscription} returns this
 */
proto.stailer_external_admin.TransactionRecord.Subscription.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.TransactionRecord.Summary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.TransactionRecord.Summary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.Summary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    transactionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transactionDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orderDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paymentType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    shopName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    businessName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    preDiscountTotalAmount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    couponDiscountedAmount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pointDiscountedAmount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    finalTotalAmount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    taxDetail: (f = msg.getTaxDetail()) && proto.stailer_external_admin.TransactionRecord.TaxDetail.toObject(includeInstance, f),
    couponPaidAmount: jspb.Message.getFieldWithDefault(msg, 15, 0),
    finalPaymentAmount: jspb.Message.getFieldWithDefault(msg, 16, 0),
    subscription: (f = msg.getSubscription()) && proto.stailer_external_admin.TransactionRecord.Subscription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Summary as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.TransactionRecord.Summary.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.transactionType;

  /** @type {?|undefined} */
  this.transactionId;

  /** @type {?|undefined} */
  this.transactionDate;

  /** @type {?|undefined} */
  this.orderDate;

  /** @type {?|undefined} */
  this.paymentType;

  /** @type {?|undefined} */
  this.shopName;

  /** @type {?|undefined} */
  this.businessName;

  /** @type {?|undefined} */
  this.userId;

  /** @type {?|undefined} */
  this.preDiscountTotalAmount;

  /** @type {?|undefined} */
  this.couponDiscountedAmount;

  /** @type {?|undefined} */
  this.pointDiscountedAmount;

  /** @type {?|undefined} */
  this.finalTotalAmount;

  /** @type {?|undefined} */
  this.taxDetail;

  /** @type {?|undefined} */
  this.couponPaidAmount;

  /** @type {?|undefined} */
  this.finalPaymentAmount;

  /** @type {?|undefined} */
  this.subscription;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.TransactionRecord.Summary.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary}
 */
proto.stailer_external_admin.TransactionRecord.Summary.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.TransactionRecord.Summary();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  obj.transactionType != null && jspb.Message.setField(msg, 2, obj.transactionType);
  obj.transactionId != null && jspb.Message.setField(msg, 3, obj.transactionId);
  obj.transactionDate != null && jspb.Message.setField(msg, 4, obj.transactionDate);
  obj.orderDate != null && jspb.Message.setField(msg, 5, obj.orderDate);
  obj.paymentType != null && jspb.Message.setField(msg, 6, obj.paymentType);
  obj.shopName != null && jspb.Message.setField(msg, 7, obj.shopName);
  obj.businessName != null && jspb.Message.setField(msg, 8, obj.businessName);
  obj.userId != null && jspb.Message.setField(msg, 9, obj.userId);
  obj.preDiscountTotalAmount != null && jspb.Message.setField(msg, 10, obj.preDiscountTotalAmount);
  obj.couponDiscountedAmount != null && jspb.Message.setField(msg, 11, obj.couponDiscountedAmount);
  obj.pointDiscountedAmount != null && jspb.Message.setField(msg, 12, obj.pointDiscountedAmount);
  obj.finalTotalAmount != null && jspb.Message.setField(msg, 13, obj.finalTotalAmount);
  obj.taxDetail && jspb.Message.setWrapperField(
      msg, 14, proto.stailer_external_admin.TransactionRecord.TaxDetail.fromObject(obj.taxDetail));
  obj.couponPaidAmount != null && jspb.Message.setField(msg, 15, obj.couponPaidAmount);
  obj.finalPaymentAmount != null && jspb.Message.setField(msg, 16, obj.finalPaymentAmount);
  obj.subscription && jspb.Message.setWrapperField(
      msg, 17, proto.stailer_external_admin.TransactionRecord.Subscription.fromObject(obj.subscription));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary}
 */
proto.stailer_external_admin.TransactionRecord.Summary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.TransactionRecord.Summary;
  return proto.stailer_external_admin.TransactionRecord.Summary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.TransactionRecord.Summary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary}
 */
proto.stailer_external_admin.TransactionRecord.Summary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.stailer_external_admin.TransactionRecord.TransactionType} */ (reader.readEnum());
      msg.setTransactionType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderDate(value);
      break;
    case 6:
      var value = /** @type {!proto.stailer_external_admin.TransactionRecord.PaymentType} */ (reader.readEnum());
      msg.setPaymentType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreDiscountTotalAmount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponDiscountedAmount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointDiscountedAmount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinalTotalAmount(value);
      break;
    case 14:
      var value = new proto.stailer_external_admin.TransactionRecord.TaxDetail;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.TaxDetail.deserializeBinaryFromReader);
      msg.setTaxDetail(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponPaidAmount(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinalPaymentAmount(value);
      break;
    case 17:
      var value = new proto.stailer_external_admin.TransactionRecord.Subscription;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.Subscription.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.TransactionRecord.Summary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.TransactionRecord.Summary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.Summary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransactionDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPaymentType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusinessName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPreDiscountTotalAmount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCouponDiscountedAmount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getPointDiscountedAmount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getFinalTotalAmount();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getTaxDetail();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.stailer_external_admin.TransactionRecord.TaxDetail.serializeBinaryToWriter
    );
  }
  f = message.getCouponPaidAmount();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getFinalPaymentAmount();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.stailer_external_admin.TransactionRecord.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TransactionType transaction_type = 2;
 * @return {!proto.stailer_external_admin.TransactionRecord.TransactionType}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getTransactionType = function() {
  return /** @type {!proto.stailer_external_admin.TransactionRecord.TransactionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.stailer_external_admin.TransactionRecord.TransactionType} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string transaction_id = 3;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transaction_date = 4;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getTransactionDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setTransactionDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string order_date = 5;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getOrderDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setOrderDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PaymentType payment_type = 6;
 * @return {!proto.stailer_external_admin.TransactionRecord.PaymentType}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getPaymentType = function() {
  return /** @type {!proto.stailer_external_admin.TransactionRecord.PaymentType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.stailer_external_admin.TransactionRecord.PaymentType} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string shop_name = 7;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string business_name = 8;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setBusinessName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string user_id = 9;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 pre_discount_total_amount = 10;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getPreDiscountTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setPreDiscountTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 coupon_discounted_amount = 11;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getCouponDiscountedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setCouponDiscountedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 point_discounted_amount = 12;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getPointDiscountedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setPointDiscountedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 final_total_amount = 13;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getFinalTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setFinalTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional TaxDetail tax_detail = 14;
 * @return {?proto.stailer_external_admin.TransactionRecord.TaxDetail}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getTaxDetail = function() {
  return /** @type{?proto.stailer_external_admin.TransactionRecord.TaxDetail} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.TransactionRecord.TaxDetail, 14));
};


/**
 * @param {?proto.stailer_external_admin.TransactionRecord.TaxDetail|undefined} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
*/
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setTaxDetail = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.clearTaxDetail = function() {
  return this.setTaxDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.hasTaxDetail = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int32 coupon_paid_amount = 15;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getCouponPaidAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setCouponPaidAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 final_payment_amount = 16;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getFinalPaymentAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setFinalPaymentAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional Subscription subscription = 17;
 * @return {?proto.stailer_external_admin.TransactionRecord.Subscription}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.getSubscription = function() {
  return /** @type{?proto.stailer_external_admin.TransactionRecord.Subscription} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.TransactionRecord.Subscription, 17));
};


/**
 * @param {?proto.stailer_external_admin.TransactionRecord.Subscription|undefined} value
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
*/
proto.stailer_external_admin.TransactionRecord.Summary.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary} returns this
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.Summary.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.TransactionRecord.SingleLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.TransactionRecord.SingleLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPrice: (f = msg.getTotalPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    isSelfMedication: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isFee: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of SingleLineItem as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.quantity;

  /** @type {?|undefined} */
  this.totalPrice;

  /** @type {?|undefined} */
  this.isSelfMedication;

  /** @type {?|undefined} */
  this.isFee;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.TransactionRecord.SingleLineItem.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.TransactionRecord.SingleLineItem}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.TransactionRecord.SingleLineItem();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.quantity != null && jspb.Message.setField(msg, 2, obj.quantity);
  obj.totalPrice && jspb.Message.setWrapperField(
      msg, 3, type_price_pb.Price.fromObject(obj.totalPrice));
  obj.isSelfMedication != null && jspb.Message.setField(msg, 4, obj.isSelfMedication);
  obj.isFee != null && jspb.Message.setField(msg, 5, obj.isFee);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.TransactionRecord.SingleLineItem}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.TransactionRecord.SingleLineItem;
  return proto.stailer_external_admin.TransactionRecord.SingleLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.TransactionRecord.SingleLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.TransactionRecord.SingleLineItem}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setTotalPrice(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSelfMedication(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.TransactionRecord.SingleLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.TransactionRecord.SingleLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getIsSelfMedication();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsFee();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.SingleLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.SingleLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional type.Price total_price = 3;
 * @return {?proto.type.Price}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.getTotalPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 3));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.stailer_external_admin.TransactionRecord.SingleLineItem} returns this
*/
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.setTotalPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.TransactionRecord.SingleLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.clearTotalPrice = function() {
  return this.setTotalPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.hasTotalPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_self_medication = 4;
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.getIsSelfMedication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.TransactionRecord.SingleLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.setIsSelfMedication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_fee = 5;
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.getIsFee = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.TransactionRecord.SingleLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.SingleLineItem.prototype.setIsFee = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.TransactionRecord.GroupLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.TransactionRecord.GroupLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalPrice: (f = msg.getTotalPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    childLineItemsList: jspb.Message.toObjectList(msg.getChildLineItemsList(),
    proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of GroupLineItem as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.totalPrice;

  /** @type {?|undefined} */
  this.childLineItemsList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.TransactionRecord.GroupLineItem.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupLineItem}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.TransactionRecord.GroupLineItem();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.totalPrice && jspb.Message.setWrapperField(
      msg, 2, type_price_pb.Price.fromObject(obj.totalPrice));
  obj.childLineItemsList && jspb.Message.setRepeatedWrapperField(
      msg, 3, obj.childLineItemsList.map(
          proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.fromObject));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupLineItem}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.TransactionRecord.GroupLineItem;
  return proto.stailer_external_admin.TransactionRecord.GroupLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.TransactionRecord.GroupLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupLineItem}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setTotalPrice(value);
      break;
    case 3:
      var value = new proto.stailer_external_admin.TransactionRecord.GroupChildLineItem;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.deserializeBinaryFromReader);
      msg.addChildLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.TransactionRecord.GroupLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.TransactionRecord.GroupLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalPrice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getChildLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional type.Price total_price = 2;
 * @return {?proto.type.Price}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.getTotalPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 2));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupLineItem} returns this
*/
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.setTotalPrice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.clearTotalPrice = function() {
  return this.setTotalPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.hasTotalPrice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated GroupChildLineItem child_line_items = 3;
 * @return {!Array<!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem>}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.getChildLineItemsList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stailer_external_admin.TransactionRecord.GroupChildLineItem, 3));
};


/**
 * @param {!Array<!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem>} value
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupLineItem} returns this
*/
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.setChildLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem}
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.addChildLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.stailer_external_admin.TransactionRecord.GroupChildLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.GroupLineItem.prototype.clearChildLineItemsList = function() {
  return this.setChildLineItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPrice: (f = msg.getTotalPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    isBundled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isSelfMedication: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of GroupChildLineItem as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.quantity;

  /** @type {?|undefined} */
  this.totalPrice;

  /** @type {?|undefined} */
  this.isBundled;

  /** @type {?|undefined} */
  this.isSelfMedication;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.TransactionRecord.GroupChildLineItem();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.quantity != null && jspb.Message.setField(msg, 2, obj.quantity);
  obj.totalPrice && jspb.Message.setWrapperField(
      msg, 3, type_price_pb.Price.fromObject(obj.totalPrice));
  obj.isBundled != null && jspb.Message.setField(msg, 4, obj.isBundled);
  obj.isSelfMedication != null && jspb.Message.setField(msg, 5, obj.isSelfMedication);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.TransactionRecord.GroupChildLineItem;
  return proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setTotalPrice(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBundled(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSelfMedication(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getIsBundled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsSelfMedication();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional type.Price total_price = 3;
 * @return {?proto.type.Price}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.getTotalPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 3));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem} returns this
*/
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.setTotalPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.clearTotalPrice = function() {
  return this.setTotalPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.hasTotalPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_bundled = 4;
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.getIsBundled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.setIsBundled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_self_medication = 5;
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.getIsSelfMedication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.TransactionRecord.GroupChildLineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.GroupChildLineItem.prototype.setIsSelfMedication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.stailer_external_admin.TransactionRecord.LineItem.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.ItemCase = {
  ITEM_NOT_SET: 0,
  SINGLE_LINE_ITEM: 1,
  GROUP_LINE_ITEM: 2
};

/**
 * @return {proto.stailer_external_admin.TransactionRecord.LineItem.ItemCase}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.getItemCase = function() {
  return /** @type {proto.stailer_external_admin.TransactionRecord.LineItem.ItemCase} */(jspb.Message.computeOneofCase(this, proto.stailer_external_admin.TransactionRecord.LineItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.TransactionRecord.LineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.TransactionRecord.LineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.LineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    singleLineItem: (f = msg.getSingleLineItem()) && proto.stailer_external_admin.TransactionRecord.SingleLineItem.toObject(includeInstance, f),
    groupLineItem: (f = msg.getGroupLineItem()) && proto.stailer_external_admin.TransactionRecord.GroupLineItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of LineItem as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.TransactionRecord.LineItem.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.singleLineItem;

  /** @type {?|undefined} */
  this.groupLineItem;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.TransactionRecord.LineItem.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.TransactionRecord.LineItem}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.TransactionRecord.LineItem();
  obj.singleLineItem && jspb.Message.setWrapperField(
      msg, 1, proto.stailer_external_admin.TransactionRecord.SingleLineItem.fromObject(obj.singleLineItem));
  obj.groupLineItem && jspb.Message.setWrapperField(
      msg, 2, proto.stailer_external_admin.TransactionRecord.GroupLineItem.fromObject(obj.groupLineItem));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.TransactionRecord.LineItem}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.TransactionRecord.LineItem;
  return proto.stailer_external_admin.TransactionRecord.LineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.TransactionRecord.LineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.TransactionRecord.LineItem}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stailer_external_admin.TransactionRecord.SingleLineItem;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.SingleLineItem.deserializeBinaryFromReader);
      msg.setSingleLineItem(value);
      break;
    case 2:
      var value = new proto.stailer_external_admin.TransactionRecord.GroupLineItem;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.GroupLineItem.deserializeBinaryFromReader);
      msg.setGroupLineItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.TransactionRecord.LineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.TransactionRecord.LineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.TransactionRecord.LineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSingleLineItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stailer_external_admin.TransactionRecord.SingleLineItem.serializeBinaryToWriter
    );
  }
  f = message.getGroupLineItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.stailer_external_admin.TransactionRecord.GroupLineItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional SingleLineItem single_line_item = 1;
 * @return {?proto.stailer_external_admin.TransactionRecord.SingleLineItem}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.getSingleLineItem = function() {
  return /** @type{?proto.stailer_external_admin.TransactionRecord.SingleLineItem} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.TransactionRecord.SingleLineItem, 1));
};


/**
 * @param {?proto.stailer_external_admin.TransactionRecord.SingleLineItem|undefined} value
 * @return {!proto.stailer_external_admin.TransactionRecord.LineItem} returns this
*/
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.setSingleLineItem = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.stailer_external_admin.TransactionRecord.LineItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.TransactionRecord.LineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.clearSingleLineItem = function() {
  return this.setSingleLineItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.hasSingleLineItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GroupLineItem group_line_item = 2;
 * @return {?proto.stailer_external_admin.TransactionRecord.GroupLineItem}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.getGroupLineItem = function() {
  return /** @type{?proto.stailer_external_admin.TransactionRecord.GroupLineItem} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.TransactionRecord.GroupLineItem, 2));
};


/**
 * @param {?proto.stailer_external_admin.TransactionRecord.GroupLineItem|undefined} value
 * @return {!proto.stailer_external_admin.TransactionRecord.LineItem} returns this
*/
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.setGroupLineItem = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.stailer_external_admin.TransactionRecord.LineItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.TransactionRecord.LineItem} returns this
 */
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.clearGroupLineItem = function() {
  return this.setGroupLineItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.LineItem.prototype.hasGroupLineItem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Summary summary = 1;
 * @return {?proto.stailer_external_admin.TransactionRecord.Summary}
 */
proto.stailer_external_admin.TransactionRecord.prototype.getSummary = function() {
  return /** @type{?proto.stailer_external_admin.TransactionRecord.Summary} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.TransactionRecord.Summary, 1));
};


/**
 * @param {?proto.stailer_external_admin.TransactionRecord.Summary|undefined} value
 * @return {!proto.stailer_external_admin.TransactionRecord} returns this
*/
proto.stailer_external_admin.TransactionRecord.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.TransactionRecord} returns this
 */
proto.stailer_external_admin.TransactionRecord.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.TransactionRecord.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LineItem line_items = 2;
 * @return {!Array<!proto.stailer_external_admin.TransactionRecord.LineItem>}
 */
proto.stailer_external_admin.TransactionRecord.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.TransactionRecord.LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stailer_external_admin.TransactionRecord.LineItem, 2));
};


/**
 * @param {!Array<!proto.stailer_external_admin.TransactionRecord.LineItem>} value
 * @return {!proto.stailer_external_admin.TransactionRecord} returns this
*/
proto.stailer_external_admin.TransactionRecord.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.stailer_external_admin.TransactionRecord.LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.TransactionRecord.LineItem}
 */
proto.stailer_external_admin.TransactionRecord.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.stailer_external_admin.TransactionRecord.LineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.TransactionRecord} returns this
 */
proto.stailer_external_admin.TransactionRecord.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.ReceiptIssuance.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.ReceiptIssuance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.ReceiptIssuance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ReceiptIssuance.toObject = function(includeInstance, msg) {
  var f, obj = {
    issuanceDate: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of ReceiptIssuance as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.ReceiptIssuance.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.issuanceDate;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.ReceiptIssuance.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.ReceiptIssuance}
 */
proto.stailer_external_admin.ReceiptIssuance.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.ReceiptIssuance();
  obj.issuanceDate != null && jspb.Message.setField(msg, 1, obj.issuanceDate);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.ReceiptIssuance}
 */
proto.stailer_external_admin.ReceiptIssuance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.ReceiptIssuance;
  return proto.stailer_external_admin.ReceiptIssuance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.ReceiptIssuance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.ReceiptIssuance}
 */
proto.stailer_external_admin.ReceiptIssuance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuanceDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.ReceiptIssuance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.ReceiptIssuance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.ReceiptIssuance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ReceiptIssuance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssuanceDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string issuance_date = 1;
 * @return {string}
 */
proto.stailer_external_admin.ReceiptIssuance.prototype.getIssuanceDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.ReceiptIssuance} returns this
 */
proto.stailer_external_admin.ReceiptIssuance.prototype.setIssuanceDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.SearchTransactionRecordsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: (f = msg.getQuery()) && proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.toObject(includeInstance, f),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of SearchTransactionRecordsRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.query;

  /** @type {?|undefined} */
  this.pageToken;

  /** @type {?|undefined} */
  this.pageSize;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.SearchTransactionRecordsRequest();
  obj.query && jspb.Message.setWrapperField(
      msg, 1, proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.fromObject(obj.query));
  obj.pageToken != null && jspb.Message.setField(msg, 2, obj.pageToken);
  obj.pageSize != null && jspb.Message.setField(msg, 3, obj.pageSize);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.SearchTransactionRecordsRequest;
  return proto.stailer_external_admin.SearchTransactionRecordsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stailer_external_admin.SearchTransactionRecordsRequest.Query;
      reader.readMessage(value,proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.SearchTransactionRecordsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.serializeBinaryToWriter
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.toObject = function(includeInstance, msg) {
  var f, obj = {
    shopId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionYearMonth: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paymentType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    minFinalTotalAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxFinalTotalAmount: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Query as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.shopId;

  /** @type {?|undefined} */
  this.transactionYearMonth;

  /** @type {?|undefined} */
  this.paymentType;

  /** @type {?|undefined} */
  this.customerId;

  /** @type {?|undefined} */
  this.minFinalTotalAmount;

  /** @type {?|undefined} */
  this.maxFinalTotalAmount;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.SearchTransactionRecordsRequest.Query();
  obj.shopId != null && jspb.Message.setField(msg, 1, obj.shopId);
  obj.transactionYearMonth != null && jspb.Message.setField(msg, 2, obj.transactionYearMonth);
  obj.paymentType != null && jspb.Message.setField(msg, 3, obj.paymentType);
  obj.customerId != null && jspb.Message.setField(msg, 4, obj.customerId);
  obj.minFinalTotalAmount != null && jspb.Message.setField(msg, 5, obj.minFinalTotalAmount);
  obj.maxFinalTotalAmount != null && jspb.Message.setField(msg, 6, obj.maxFinalTotalAmount);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.SearchTransactionRecordsRequest.Query;
  return proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionYearMonth(value);
      break;
    case 3:
      var value = /** @type {!proto.stailer_external_admin.TransactionRecord.PaymentType} */ (reader.readEnum());
      msg.setPaymentType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinFinalTotalAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxFinalTotalAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionYearMonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaymentType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMinFinalTotalAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxFinalTotalAmount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string shop_id = 1;
 * @return {string}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transaction_year_month = 2;
 * @return {string}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.getTransactionYearMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.setTransactionYearMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TransactionRecord.PaymentType payment_type = 3;
 * @return {!proto.stailer_external_admin.TransactionRecord.PaymentType}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.getPaymentType = function() {
  return /** @type {!proto.stailer_external_admin.TransactionRecord.PaymentType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.stailer_external_admin.TransactionRecord.PaymentType} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string customer_id = 4;
 * @return {string}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 min_final_total_amount = 5;
 * @return {number}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.getMinFinalTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.setMinFinalTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_final_total_amount = 6;
 * @return {number}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.getMaxFinalTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.Query.prototype.setMaxFinalTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Query query = 1;
 * @return {?proto.stailer_external_admin.SearchTransactionRecordsRequest.Query}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.getQuery = function() {
  return /** @type{?proto.stailer_external_admin.SearchTransactionRecordsRequest.Query} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.SearchTransactionRecordsRequest.Query, 1));
};


/**
 * @param {?proto.stailer_external_admin.SearchTransactionRecordsRequest.Query|undefined} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest} returns this
*/
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsRequest} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.SearchTransactionRecordsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionRecordSummariesList: jspb.Message.toObjectList(msg.getTransactionRecordSummariesList(),
    proto.stailer_external_admin.TransactionRecord.Summary.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of SearchTransactionRecordsResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.transactionRecordSummariesList;

  /** @type {?|undefined} */
  this.nextPageToken;

  /** @type {?|undefined} */
  this.totalCount;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsResponse}
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.SearchTransactionRecordsResponse();
  obj.transactionRecordSummariesList && jspb.Message.setRepeatedWrapperField(
      msg, 1, obj.transactionRecordSummariesList.map(
          proto.stailer_external_admin.TransactionRecord.Summary.fromObject));
  obj.nextPageToken != null && jspb.Message.setField(msg, 2, obj.nextPageToken);
  obj.totalCount != null && jspb.Message.setField(msg, 3, obj.totalCount);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsResponse}
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.SearchTransactionRecordsResponse;
  return proto.stailer_external_admin.SearchTransactionRecordsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsResponse}
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stailer_external_admin.TransactionRecord.Summary;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.Summary.deserializeBinaryFromReader);
      msg.addTransactionRecordSummaries(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.SearchTransactionRecordsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.SearchTransactionRecordsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionRecordSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.stailer_external_admin.TransactionRecord.Summary.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated TransactionRecord.Summary transaction_record_summaries = 1;
 * @return {!Array<!proto.stailer_external_admin.TransactionRecord.Summary>}
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.getTransactionRecordSummariesList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.TransactionRecord.Summary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stailer_external_admin.TransactionRecord.Summary, 1));
};


/**
 * @param {!Array<!proto.stailer_external_admin.TransactionRecord.Summary>} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsResponse} returns this
*/
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.setTransactionRecordSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.stailer_external_admin.TransactionRecord.Summary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.TransactionRecord.Summary}
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.addTransactionRecordSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.stailer_external_admin.TransactionRecord.Summary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsResponse} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.clearTransactionRecordSummariesList = function() {
  return this.setTransactionRecordSummariesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsResponse} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 total_count = 3;
 * @return {number}
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.SearchTransactionRecordsResponse} returns this
 */
proto.stailer_external_admin.SearchTransactionRecordsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.GetTransactionRecordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.GetTransactionRecordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.GetTransactionRecordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetTransactionRecordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of GetTransactionRecordRequest as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.GetTransactionRecordRequest.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.GetTransactionRecordRequest.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.GetTransactionRecordRequest}
 */
proto.stailer_external_admin.GetTransactionRecordRequest.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.GetTransactionRecordRequest();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.GetTransactionRecordRequest}
 */
proto.stailer_external_admin.GetTransactionRecordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.GetTransactionRecordRequest;
  return proto.stailer_external_admin.GetTransactionRecordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.GetTransactionRecordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.GetTransactionRecordRequest}
 */
proto.stailer_external_admin.GetTransactionRecordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.GetTransactionRecordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.GetTransactionRecordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.GetTransactionRecordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetTransactionRecordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stailer_external_admin.GetTransactionRecordRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.GetTransactionRecordRequest} returns this
 */
proto.stailer_external_admin.GetTransactionRecordRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stailer_external_admin.GetTransactionRecordResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.GetTransactionRecordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.GetTransactionRecordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetTransactionRecordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionRecord: (f = msg.getTransactionRecord()) && proto.stailer_external_admin.TransactionRecord.toObject(includeInstance, f),
    receiptIssuancesList: jspb.Message.toObjectList(msg.getReceiptIssuancesList(),
    proto.stailer_external_admin.ReceiptIssuance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of GetTransactionRecordResponse as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.GetTransactionRecordResponse.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.transactionRecord;

  /** @type {?|undefined} */
  this.receiptIssuancesList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.GetTransactionRecordResponse.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.GetTransactionRecordResponse}
 */
proto.stailer_external_admin.GetTransactionRecordResponse.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.GetTransactionRecordResponse();
  obj.transactionRecord && jspb.Message.setWrapperField(
      msg, 1, proto.stailer_external_admin.TransactionRecord.fromObject(obj.transactionRecord));
  obj.receiptIssuancesList && jspb.Message.setRepeatedWrapperField(
      msg, 2, obj.receiptIssuancesList.map(
          proto.stailer_external_admin.ReceiptIssuance.fromObject));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.GetTransactionRecordResponse}
 */
proto.stailer_external_admin.GetTransactionRecordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.GetTransactionRecordResponse;
  return proto.stailer_external_admin.GetTransactionRecordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.GetTransactionRecordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.GetTransactionRecordResponse}
 */
proto.stailer_external_admin.GetTransactionRecordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stailer_external_admin.TransactionRecord;
      reader.readMessage(value,proto.stailer_external_admin.TransactionRecord.deserializeBinaryFromReader);
      msg.setTransactionRecord(value);
      break;
    case 2:
      var value = new proto.stailer_external_admin.ReceiptIssuance;
      reader.readMessage(value,proto.stailer_external_admin.ReceiptIssuance.deserializeBinaryFromReader);
      msg.addReceiptIssuances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.GetTransactionRecordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.GetTransactionRecordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.GetTransactionRecordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionRecord();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stailer_external_admin.TransactionRecord.serializeBinaryToWriter
    );
  }
  f = message.getReceiptIssuancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.stailer_external_admin.ReceiptIssuance.serializeBinaryToWriter
    );
  }
};


/**
 * optional TransactionRecord transaction_record = 1;
 * @return {?proto.stailer_external_admin.TransactionRecord}
 */
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.getTransactionRecord = function() {
  return /** @type{?proto.stailer_external_admin.TransactionRecord} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.TransactionRecord, 1));
};


/**
 * @param {?proto.stailer_external_admin.TransactionRecord|undefined} value
 * @return {!proto.stailer_external_admin.GetTransactionRecordResponse} returns this
*/
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.setTransactionRecord = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.GetTransactionRecordResponse} returns this
 */
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.clearTransactionRecord = function() {
  return this.setTransactionRecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.hasTransactionRecord = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ReceiptIssuance receipt_issuances = 2;
 * @return {!Array<!proto.stailer_external_admin.ReceiptIssuance>}
 */
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.getReceiptIssuancesList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.ReceiptIssuance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stailer_external_admin.ReceiptIssuance, 2));
};


/**
 * @param {!Array<!proto.stailer_external_admin.ReceiptIssuance>} value
 * @return {!proto.stailer_external_admin.GetTransactionRecordResponse} returns this
*/
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.setReceiptIssuancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.stailer_external_admin.ReceiptIssuance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.ReceiptIssuance}
 */
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.addReceiptIssuances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.stailer_external_admin.ReceiptIssuance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.GetTransactionRecordResponse} returns this
 */
proto.stailer_external_admin.GetTransactionRecordResponse.prototype.clearReceiptIssuancesList = function() {
  return this.setReceiptIssuancesList([]);
};


goog.object.extend(exports, proto.stailer_external_admin);
